import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Roles, UserProfile } from '../models/profile.models';
import { HttpService } from './http.service';
import jwtDecode from "jwt-decode";
import { environment } from 'environments/environment';
import moment from 'moment'
import { Company, EntityDefinition, FieldType } from 'app/models/entities.model';
import { SwitchDataLakeService } from './switch-data-lake.service';
import { BlockChainService } from './blockchain.service';


@Injectable()
export class StoredObjectService {
	// private baseUrl = environment.services.apiBasePath;
	
	constructor(private _httpService: HttpService, private _chainService: BlockChainService, private _dataLake: SwitchDataLakeService) {
	}

	private getBaseUrl = (entityDefinition: EntityDefinition) => {
		let entities: string[] = entityDefinition.entityId.split(".");
		let url = environment.services.apiBasePath + "/" + entities[0] + "/" +entityDefinition.entityId;
		return url;
	}

	public getAll = (entityDefinition: EntityDefinition, fieldsName?: string[]): Observable<any[]> => {
		let queryParams = ""
		if (fieldsName && fieldsName.length>0) {
			queryParams = "?";
			fieldsName.forEach(field => {
				queryParams += "fields="+field+ "&";
			})
		}

		return this._httpService.get(this.getBaseUrl(entityDefinition) + queryParams, "json");
	}



	public getById = (entityDefinition: EntityDefinition, id: any): Observable<any> => {
		let url = this.getBaseUrl(entityDefinition) + "/" + id;
		return this._httpService.get(url);
	}
	public getByField = (entityDefinition: EntityDefinition, fieldId: string, value: any): Observable<any> => {
		let url = this.getBaseUrl(entityDefinition) + "/" + fieldId + "/" + value;
		return this._httpService.get(url);
	}
	public delete = (entityDefinition: EntityDefinition, id: any): Observable<any> => {
		let url = this.getBaseUrl(entityDefinition) + "/" + id;
		return this._httpService.delete(url);
	}

	public saveOrUpdate = (entityDefinition: EntityDefinition, entity: any): Observable<any> => {
		let url = this.getBaseUrl(entityDefinition);
		entity.lastUpdateDate = moment().format("DD-MM-YYYY hh:mm");
		if (entity.id)
			return this._httpService.put(url + "/"+entity.id , entity,"json");
		return this._httpService.post(url,entity,"json")
	}


	private getImageHash(imageId): Observable<string>{
		return this._httpService.get(environment.services.mediaContentsBasePath + "/"+imageId + "/hash", "text");
	}
	private getEntityDef = (entity: EntityDefinition, entityId): EntityDefinition => {
		if (entity && entity.entityId == entityId) {
			return entity;
		}
		let found: EntityDefinition;
		if (entity.entityChilds) {
			entity.entityChilds.forEach((children) => {
				let hint = this.getEntityDef(children,entityId);
				if (hint) {
					found = hint;
				}
			})	
		}
		return found;
	}
	public certify = async (data: any, historypath: any[], rootEntityDef: EntityDefinition, refEntityDef: EntityDefinition, userProfile: UserProfile, secret: string): Promise<any> => {
		console.log("Data to certify ", data);
		console.log("	rootEntityDef", rootEntityDef);
		console.log("	refEntityDef", refEntityDef);
		
		let fieldsToCertify : any = {};
		let isFirst = true;
		// console.log("rootAbstractEntityId ", this.rootAbstractEntityId);
		// console.log(("idWhitParent " + this.idWhitParent));
		// console.log("historypath",this.historypath);
		// fieldsToCertify["azienda"] = rootEntityDef.name + "." + obj.id;

		let companyId = null;
		for (const path of historypath) {
			console.log("history: evaluate ", path);
			
			let entityDef: EntityDefinition = this.getEntityDef(rootEntityDef, path.abstractEntityId);
			await this.getById(entityDef, path.objectId ).toPromise().then(async (obj) => {
				if (isFirst) {
					companyId = rootEntityDef.name + "." + obj.id;
					isFirst = false;
				}
				for (const def of entityDef.baseInfo) {
					if (def.fieldToCertify && obj[def.fieldLabel]) {
						if (def.fieldType == FieldType.FILE || def.fieldType == FieldType.IMAGE) {
							fieldsToCertify[path.entityName + " - " + def.fieldLabel+"_url"] = environment.services.mediaContentsBasePath + "/"+  obj[def.fieldLabel]; 
							await this.getImageHash(obj[def.fieldLabel]).toPromise().then((imgHash) => {
								fieldsToCertify[path.entityName + " - " + def.fieldLabel+"_hash"] = imgHash;
							});
						}
						else if (def.fieldType == FieldType.DATE) {
							fieldsToCertify[path.entityName + " - " + def.fieldLabel] = moment(obj[def.fieldLabel]).format("DD/MM/YYYY");

						}
						else if (def.fieldType == FieldType.MULTIPLE_SELECT &&  obj[def.fieldLabel] &&  obj[def.fieldLabel].join) {
							fieldsToCertify[path.entityName + " - " + def.fieldLabel] =  obj[def.fieldLabel]?.join(", ");
						}

						else {
							fieldsToCertify[path.entityName + " - " + def.fieldLabel] = obj[def.fieldLabel];
						}
					}
				}
				for (const group of entityDef.groups) { 
					for (const def of group.fields) {
						if (def.fieldToCertify &&  obj[group.groupLabel] && obj[group.groupLabel][def.fieldLabel]) {
							if (def.fieldType == FieldType.FILE || def.fieldType == FieldType.IMAGE) {
								fieldsToCertify[path.entityName + " - " + group.groupLabel + " - " + def.fieldLabel+"_url"] = environment.services.mediaContentsBasePath + "/"+  obj[group.groupLabel][def.fieldLabel]; 
								await this.getImageHash(obj[group.groupLabel][def.fieldLabel]).toPromise().then((imgHash) => {
									fieldsToCertify[path.entityName + " - " + group.groupLabel + " - "  + def.fieldLabel+"_hash"] = imgHash;
								});
							}
							else if (def.fieldType == FieldType.DATE) {
								fieldsToCertify[path.entityName + " - " + group.groupLabel + " - "  + def.fieldLabel] = moment(obj[group.groupLabel][def.fieldLabel]).format("DD/MM/YYYY");
	
							}
							else if (def.fieldType == FieldType.MULTIPLE_SELECT &&  obj[group.groupLabel][def.fieldLabel] &&  obj[group.groupLabel][def.fieldLabel].join) {
								fieldsToCertify[path.entityName + " - " + group.groupLabel + " - "  + def.fieldLabel] =  obj[group.groupLabel][def.fieldLabel]?.join(", ");
							}
							else {
								fieldsToCertify[path.entityName + " - " + group.groupLabel + " - "  + def.fieldLabel] = obj[group.groupLabel][def.fieldLabel];
							}
						}
					}
					
				}
			})
		}

		for (const def of refEntityDef.baseInfo) {
			console.log("evaluating base info");
			
			if (isFirst) {
				companyId = rootEntityDef.name + "." + data.id;
				isFirst = false;
			}
			if (def.fieldToCertify && data[def.fieldLabel]) {
				if (def.fieldType == FieldType.FILE || def.fieldType == FieldType.IMAGE) {
					fieldsToCertify[refEntityDef.name + " - " + def.fieldLabel+"_url"] = environment.services.mediaContentsBasePath + "/"+  data[def.fieldLabel]; 
					await this.getImageHash(data[def.fieldLabel]).toPromise().then((imgHash) => {
						fieldsToCertify[refEntityDef.name + " - " + def.fieldLabel+"_hash"] = imgHash;
					});
				}
				else if (def.fieldType == FieldType.DATE) {
					fieldsToCertify[refEntityDef.name + " - " + def.fieldLabel] = moment(data[def.fieldLabel]).format("DD/MM/YYYY");

				}
				else if (def.fieldType == FieldType.MULTIPLE_SELECT &&  data[def.fieldLabel] &&  data[def.fieldLabel].join) {
					fieldsToCertify[refEntityDef.name + " - " + def.fieldLabel]=  data[def.fieldLabel]?.join(", ");
				}
				else {
					fieldsToCertify[refEntityDef.name + " - " + def.fieldLabel] = data[def.fieldLabel];
				}
			}
		}
		for (const group of refEntityDef.groups) { 
			console.log("evaluating group of entity",group);

			for (const def of group.fields) {
				console.log("controllo ", def, data[group.groupLabel][def.fieldLabel]);
				
				if (def.fieldToCertify && data[group.groupLabel] && data[group.groupLabel][def.fieldLabel]) {
					if (def.fieldType == FieldType.FILE || def.fieldType == FieldType.IMAGE) {
						fieldsToCertify[refEntityDef.name + " - " + group.groupLabel + " - " + def.fieldLabel+"_url"] = environment.services.mediaContentsBasePath + "/"+  data[group.groupLabel][def.fieldLabel]; 
						await this.getImageHash(data[group.groupLabel][def.fieldLabel]).toPromise().then((imgHash) => {
							fieldsToCertify[refEntityDef.name + " - " + group.groupLabel + " - "  + def.fieldLabel+"_hash"] = imgHash;
						});
					}
					else if (def.fieldType == FieldType.DATE) {
						fieldsToCertify[refEntityDef.name + " - " + group.groupLabel + " - "  + def.fieldLabel] = moment(data[group.groupLabel][def.fieldLabel]).format("DD/MM/YYYY");

					}
					else if (def.fieldType == FieldType.MULTIPLE_SELECT &&  data[group.groupLabel][def.fieldLabel] && data[group.groupLabel][def.fieldLabel].join) {
						fieldsToCertify[refEntityDef.name + " - " + group.groupLabel + " - "  + def.fieldLabel] = data[group.groupLabel][def.fieldLabel]?.join(", ");
					}
					else {
						fieldsToCertify[refEntityDef.name + " - " + group.groupLabel + " - "  + def.fieldLabel] = data[group.groupLabel][def.fieldLabel];
					}
				}
			}
			
		}

		console.log("to certify:",fieldsToCertify);
		



		// if (company.province) fieldsToCertify["indirizzo azienda"] = company.address + ", " + company.cap + " " + company.city+ " (" + company.province +")" ;
		// else fieldsToCertify["indirizzo azienda"] = company.address + ", " + company.cap + " " + company.city ;
		// fieldsToCertify["prodotto"] = product.name;
		
		// if (product.productFields && product.productFields.length>0) {
		// 	product.productFields.forEach((field) => {
		// 		fieldsToCertify["Prodotto - " + field.name] = field.value;
		// 	})
		// }
		// if (product.image) {
		// 	fieldsToCertify["immagine_prodotto_url"] = environment.services.mediaContentsBasePath + "/"+ product.image; 
		// 	await this.getImageHash(product.image).toPromise().then((imgHash) => {
		// 		fieldsToCertify["immagine_prodotto_hash"] = imgHash;
		// 	});
		// }
		
		// if (product.image_3d) {
		// 	fieldsToCertify["immagine3d_url"] =  environment.services.mediaContentsBasePath + "/"+product.image_3d;
		// 	await this.getImageHash(product.image_3d).toPromise().then((imgHash) => {
		// 		fieldsToCertify["immagine3d_hash"]= imgHash;
		// 	});
		// }
		// for (const group of entityDef.groups)  {
		// 	if (group.isArray) {
		// 		if (data[group.groupLabel] && data[group.groupLabel].length > 0) {
		// 			let index = 0;
		// 			for (const element of data[group.groupLabel]) {
		// 				let values = [];
		// 				for (const field of group.fields) {
		// 					if (field.fieldToCertify &&  element[field.fieldLabel]) {
		// 						if (field.fieldType == FieldType.IMAGE || field.fieldType == FieldType.FILE) {
		// 							values.push(field.fieldLabel + "_url: " + environment.services.mediaContentsBasePath + "/"+ element[field.fieldLabel]); 
		// 							await this.getImageHash(element[field.fieldLabel]).toPromise().then((imgHash) => {
		// 								values.push[field.fieldLabel + "_hash: " + imgHash];
		// 							});
		// 						}
		// 						else if (field.fieldType == FieldType.DATE) {
		// 							values.push(field.fieldLabel + ": " + moment( element[field.fieldLabel]).format("DD/MM/YYYY"));
		// 						}
		// 						else if (field.fieldType == FieldType.MULTIPLE_SELECT &&  element[field.fieldLabel] &&  element[field.fieldLabel].join) {
		// 							values.push(field.fieldLabel + ": " +  element[field.fieldLabel]?.join(", "));
		// 						}
		// 						else {
		// 							values.push(field.fieldLabel + ": " + element[field.fieldLabel]);
		// 						}
		// 					}
		// 				}
		// 				fieldsToCertify[(++index) + ". " + group.groupLabel] = values.join(" - ");
		// 			};
		// 		}

		// 	}
		// 	else {
		// 		for (const field of group.fields) {
		// 			if (field.fieldToCertify && data[group.groupLabel][field.fieldLabel]) {
		// 				if (field.fieldType == FieldType.IMAGE || field.fieldType == FieldType.FILE) {
		// 					fieldsToCertify[""+field.fieldLabel + "_url"] =  environment.services.mediaContentsBasePath + "/"+ data[group.groupLabel][field.fieldLabel];
		// 					await this.getImageHash(data[group.groupLabel][field.fieldLabel]).toPromise().then((imgHash) => {
		// 						fieldsToCertify[""+field.fieldLabel + "_hash"]= imgHash;
		// 					});
		// 				}
		// 				else if (field.fieldType == FieldType.DATE) {
		// 					fieldsToCertify[""+field.fieldLabel] =  moment(data[group.groupLabel][field.fieldLabel]).format("DD/MM/YYYY");
		// 				}
		// 				else if (field.fieldType == FieldType.MULTIPLE_SELECT) {
		// 					fieldsToCertify[""+field.fieldLabel] =  data[group.groupLabel][field.fieldLabel].join(", ");
		// 				}
						
		// 				else {
		// 					fieldsToCertify[""+field.fieldLabel] =  data[group.groupLabel][field.fieldLabel];

		// 				}
		// 			}
		// 		}
		// 	}
		// }
		console.log("Data to certify ", fieldsToCertify);

		

		let toSend: any = new Object();
		toSend.identity = userProfile.email;
		toSend.name = userProfile.firstName + " " + userProfile.lastName;
		toSend.pubkey = userProfile.walletInfo.pubKey;

		toSend.azienda = companyId;
		// toSend.azienda = company.companyId;
		toSend.cert_id = data.id;

		toSend.data = fieldsToCertify;
		
		// toSend.decorator_name = product.name + " - " + entityDef.name;
		// toSend.decorator_description = entityDef.name + " relativo a " + product.name;
		// toSend.decorator_external_url = URLUtils.getCertPageURL(company,product, entityDef, data)
		// toSend.decorator_image =  environment.services.mediaContentsBasePath + "/"+product.image;
		// if (product.image)
		// 	toSend.decorator_image =  environment.services.mediaContentsBasePath + "/"+product.image;
		// if (product.image_3d)
		// 	toSend.decorator_image_3d =  environment.services.mediaContentsBasePath + "/"+product.image_3d;



		console.log("toSend, secret ",toSend, secret);
		
		// return of(null).toPromise();
		return new Observable((observer) => {
			this._chainService.certifyEntity(toSend, secret).subscribe(
				(result) => {
					if (result) {
						data.certified = true;
						data.certifiedInDateUTC = moment().format();
						this.saveOrUpdate(refEntityDef, data).subscribe((r) => {
							observer.next(result);
							observer.complete();
						})
					}
					else {
						observer.next(null);
						observer.complete();
					}
				},
				(error) => {
					observer.next(null);
					observer.complete();
				})

		}).toPromise();

	}
}