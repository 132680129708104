import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalOptions } from "app/common/models/models";
import { EntityDefinition, ValidatorType } from "app/models/entities.model";
import { ModalService } from "app/common/services/modal.service";
import { EntityConfigurationService } from "app/services/entity-configuration.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { ACR } from "app/services/acr.service";

@Component({
    selector: 'object-form',
    templateUrl: './object-form.component.html'
})
export class ObjectFormComponent implements OnInit {
	
	@Input("entityDef") public entityDef: EntityDefinition;
	private _objectIstance;
	@Input("objectIstance") public set objectIstance(value) {
		this._objectIstance = value;
		if (this.formGroup) this.formGroup.patchValue(value);
	};
	@Input("userACRs") public userACRs: ACR[] = [];
	@Output("onObjectUpdate") public onObjectUpdate: EventEmitter<any> = new EventEmitter<any>();
	@Output("valid") public valid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Input("readonly") public readonly: boolean = false;
	
	formGroup: FormGroup;

	constructor(private _fb: FormBuilder) {

	}

	ngOnInit(): void {
		let obj: any = {
			id: [(this._objectIstance)? this._objectIstance.id: null]
		}

		this.entityDef.baseInfo.forEach((field) => {
			let validators = [];
			if (field.validators) {
				field.validators.forEach((validator) => {
					if (validator.validatorType == ValidatorType.REQUIRED) validators.push(CommonValidators.required); 
				})
			}
			obj[field.fieldLabel] = [
				(this._objectIstance && this._objectIstance[field.fieldLabel])? this._objectIstance[field.fieldLabel]: field.defaultValue,
				validators
			]
		})

		if (this.entityDef.groups) {

			this.entityDef.groups.forEach((group) => {
				let gpForm: any = {};
				group.fields.forEach((field) => {
					let validators = [];
					if (field.validators) {
						field.validators.forEach((validator) => {
							if (validator.validatorType == ValidatorType.REQUIRED) validators.push(CommonValidators.required); 
						})
					}
					gpForm[field.fieldLabel] = [
						(this._objectIstance && this._objectIstance[group.groupLabel] && this._objectIstance[group.groupLabel][field.fieldLabel]) ?
							this._objectIstance[group.groupLabel][field.fieldLabel]: field.defaultValue,
						validators
					]
				})
				obj[group.groupLabel] = this._fb.group(gpForm);
			})
		}

		this.formGroup = this._fb.group(obj);
		
		this.formGroup.valueChanges.subscribe((change) => {
			this.onObjectUpdate.emit(change);
		})
		this.formGroup.statusChanges.subscribe((status) => {
			this.valid.emit(status != "INVALID");
		})

		this.valid.emit(this.formGroup.valid);
	}
}