import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ArchwizardModule } from 'angular-archwizard';
import { PipeModule } from 'app/pipes/pipe.module';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LandingPageV3 } from './landing-page-v3.page';

export const LandingPageV3Routes: Routes = [
    { path: 'landing-v3', component: LandingPageV3, canActivate: []},
    { path: 'landing-v3/:lpDomainPath/:lpId/:entityDomain/:certId', component: LandingPageV3, canActivate: []}
];

@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
		BrowserModule,
        ComponentsModule,
        RouterModule.forChild(LandingPageV3Routes),
		NgJsonEditorModule,
		ArchwizardModule,
		PipeModule
		,
		NgbModule
    ],
    exports: [
	],
    declarations: [
		LandingPageV3
    ],
    providers: [],
	entryComponents: [
	]
})
export class V3LandingPageModule { }