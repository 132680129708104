import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ArchwizardModule } from 'angular-archwizard';
import { UserProfilePage } from './user-profile-page/user-profile.page';
import { PipeModule } from 'app/pipes/pipe.module';
import { UnauthorizedModule } from '../unautorized-page/unautorized.module';
import { AuthGuard } from 'app/services/auth-gard.service';
import { WalletImportModalForm } from './wallet-import-modal/wallet-import-modal.page';
import { NgbAccordion, NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AbstractEntityManagementModule } from '../abstract-entity-management/abstract-entity-management.module';



export const UserProfileRoutes: Routes = [
    { path: 'user-profile', component: UserProfilePage, canActivate: [AuthGuard]},
    
];


@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        RouterModule.forChild(UserProfileRoutes),
		NgJsonEditorModule,
		ArchwizardModule,
		UnauthorizedModule,
		PipeModule,
		NgbModule,
		AbstractEntityManagementModule
    ],
    exports: [
		WalletImportModalForm
	],
    declarations: [
		UserProfilePage,
		WalletImportModalForm
    ],
    providers: [],
	entryComponents: [
		WalletImportModalForm
	]
})
export class UserProfileModule { }
