import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { iconCancel, iconSave } from "app/common/utils/icons.utils";

import { ProfileService } from 'app/services/profile.service';
import { EntityDefinition, GroupDefinition } from 'app/models/entities.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Roles } from 'app/models/profile.models';
import { URLUtils } from 'app/services/url-utils';
import { cleanString, nameFieldsValidators } from 'app/common/models/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'edit-abstract-entity-modal',
    templateUrl: './edit-abstract-entity.modal.html'
})
export class EditAbstractEntityModalForm implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	iconCancel = iconCancel;
	iconSave = iconSave;
	public entityForm: FormGroup = null;	
	public groups: GroupDefinition[] = [];
	public groupValidity: boolean[] = [];
	data: {
		entity: EntityDefinition
	};
	dataLoaded: boolean = false;

	ngOnInit() {
		let entity;
		if (this.data && this.data.entity) {
			entity = this.data.entity;
		}
		this.initForm(entity);
		this.dataLoaded = true;
	}

    constructor(
		private activeModal: NgbActiveModal,
		private _fb: FormBuilder,
		private _translateService: TranslateService,
		private _userProfileService: ProfileService) {
			
    }

	addGroup = (value?: GroupDefinition) => {
		if (!value) {
			value = new GroupDefinition();
			value.fields = [];
		}
		this.groups.push(value);
		this.groupValidity.push(false);
	}
	removeGroup = (index: number) => {
		this.groups.splice(index,1);
		this.groupValidity.splice(index,1);
	}
	validateGroup = (index: number, valid: boolean) => {
		this.groupValidity[index] = valid;
	}
	
	isEdit = false;
	private initForm = (initData?: EntityDefinition) => {
		if (initData && initData.entityId) this.isEdit = true;
		let entityFormDef: any = {
			entityId: [(initData)? initData.entityId: null],
			name: [(initData)? initData.name: null, nameFieldsValidators],
			// landingPageUrl: [(initData)?initData.landingPageUrl:URLUtils.getDefaultEntityLandingPagePatternURL(), CommonValidators.websiteFormatValidator],
			// certVerifyerUrl: [(initData)?initData.certVerifyerUrl:URLUtils.getDefaultCertPagePatternURL(), CommonValidators.websiteFormatValidator]
			landingPageUrl: [(initData)?initData.landingPageUrl:URLUtils.getDefaultEntityLandingPagePatternURL()],
			certVerifyerUrl: [(initData)?initData.certVerifyerUrl:URLUtils.getDefaultCertPagePatternURL()]
		};

		this.entityForm = this._fb.group(entityFormDef);
		if (initData && initData.groups) {
			this.addGroup({
				fields: initData.baseInfo,
				groupId: "gid!!",
				groupLabel: this._translateService.instant("abstract-entity.editor.baseInformationLabel") ,
				isArray: false
			})

			initData.groups.forEach((group) => {
				this.addGroup(group);
			})
		}
		else {
			this.addGroup({
				fields: [],
				groupId: "gid!!",
				groupLabel: this._translateService.instant("abstract-entity.editor.baseInformationLabel") ,
				isArray: false
			})
		}

		this.entityForm.get("name").valueChanges.subscribe((name) => {
			if (name)
				this.entityForm.get("entityId").patchValue(cleanString(name));
		})
	}

	get isAdmin() {
		return this._userProfileService.isInRole(Roles.ADMIN);
	}

    public save(certData?: boolean) {
		
        if (this.canSaveForm) {
			
			let toSave: EntityDefinition = this.entityForm.value;
			toSave.baseInfo = this.groups.filter((group) => {return group.groupId=="gid!!"})[0].fields
			
			toSave.groups = this.groups.filter((group) => {return group.groupId!="gid!!"});

			this.activeModal.close(toSave);
        }
		else {
		}
    }

	public get canSaveForm(): boolean {
		return this.entityForm.valid && this.groupValidity.indexOf(false)<0;
	}

	public close = (): void => {
		this.activeModal.close(false);
    }
}


