import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalOptions } from "app/common/models/models";
import { EntityDefinition } from "app/models/entities.model";
import { ModalService } from "app/common/services/modal.service";
import { StoredObjectService } from "app/services/stored-object.service";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "app/common/components/base.component";
import { URLUtils } from "app/services/url-utils";
import { UserProfile } from "app/models/profile.models";
import { ACR } from "app/services/acr.service";
import { AbstractEntityLandingPageModalForm } from "../../configurator/landing-page-editor/abs-landing-page-editor-modal.page";

export interface DataReloader {
	reloadData();
}
@Component({
    selector: 'list-object-preview',
    templateUrl: './list-object-preview.component.html'
})
export class ListObjectPreviewComponent extends BaseComponent implements OnInit, DataReloader {
	
	@Input("entityDef") public entityDef: EntityDefinition;
	@Input("rootEntityDef") public rootEntityDef: EntityDefinition;
	@Input("currentObject") public currentObject: any;
	@Input("parentId") public parentId: any;
	@Input("userProfile") public userProfile: UserProfile;
	@Input("userACRs") public userACRs: ACR[] = [];

	@Output("dataReloader") dataReloader: EventEmitter<DataReloader> = new EventEmitter();
	@Output("objectClick") objectClick: EventEmitter<any> = new EventEmitter();
	entities = [];
	constructor(
		private _storedObjectService: StoredObjectService,
		private _modalService: ModalService,
		private _activatedRoute: ActivatedRoute
	) {
		super();
	}

	reloadData() {
		
		this._storedObjectService.getAll(this.entityDef).subscribe((result: any) => {
			if (this.parentId) {
				this.entities = result.filter((value) => {
					return value.parentId == this.parentId;
				})
			}
			else {
				this.entities = result;
			}			
		})

		// this._storedObjectService.getAll(this.entityDef)
		// .pipe(
		// 	map((r) => {
		// 		if (this.parentId) return r.filter((value) => {
		// 			return value.parentId == this.parentId;
		// 		})
		// 		return r;
		// 	})
		// )
		// .subscribe((objs) => {
		// 	console.log("obj preview ", objs);
			
		// 	this.entities = objs;
		// })
	}
	ngOnInit(): void {
		this.reloadData();
		this.dataReloader.emit(this);
	}

	public onDeleteRequest = (item: any) => {
		let callback = (data) => {
			if (data) {
				this._storedObjectService.delete(this.entityDef, item.id).subscribe((result) => {
					this.ngOnInit();
				})
			}
		}
		this._modalService.showConfirm("common.dialogs.alertRemoveItemTitle", "common.dialogs.alertRemoveItemMessage", {
			callback: callback,
			size: "md"
		});
	}

	public onRequestEditLP = (entityDef: EntityDefinition, obj: any) => {
		console.log("Object ", obj);
		
		let modalOption: ModalOptions<EntityDefinition> = {
			size: 'xl',
			callback: (res) => {
				if (res) {
					obj.hasLPDefinition = true;
					this._storedObjectService.saveOrUpdate(entityDef,obj).subscribe((result) => {						
					})
				}
				
			}
		}
		let data = {
			entity: entityDef,
			rootAbstractEntityId:  this.rootEntityDef,
			object: obj,
			userProfile: this.userProfile,
			pageId: obj.id
		}
		this._modalService.show(AbstractEntityLandingPageModalForm, data, modalOption);
	}
	public showCert = (object: any) => {
		let parentId;
		if (this.parentId) {
			let p = this.parentId.split(".");
			if (p[0] != "root") parentId = p[0];
			else parentId = p[1];
		}
		else {
			parentId = object.id;
		}

		let url = URLUtils.getNewCertUrl(object, this.rootEntityDef, parentId);
		url = url.split(" ").join("_");
		window.open(url, "_blank");
	}

	public showLP = (entityDef: EntityDefinition, obj: any) => {
		let parentId;
		if (this.parentId) {
			let p = this.parentId.split(".");
			if (p[0] != "root") parentId = p[0];
			else parentId = p[1];
		}
		else {
			parentId = obj.id;
		}
		
		

		let url = `/landing-v3/${entityDef.entityId}_${obj.id}/${obj.id}/${this.rootEntityDef.name}.${parentId}/${obj.id}`;
		url = url.split(" ").join("_");
		window.open(url, "_blank");
	}

	
	public goToEntityHome = (object?: any) => {
		this._activatedRoute.paramMap.subscribe(qp => {
			let parentId = "root"
			if (this.rootEntityDef.entityId != this.entityDef.entityId) {
				parentId = qp.get("parentIdChain") + ((this.currentObject && this.currentObject.id)? "." + this.currentObject.id: "") ;
			}
			this.on(
				this._activatedRoute.paramMap.subscribe(qp => {
					if (object) {
						this.objectClick.emit({rootEntityDef: this.rootEntityDef, entityDef: this.entityDef, currentObject: this.currentObject, parentId: parentId, object: object});
					}
					else {
						this.objectClick.emit({rootEntityDef: this.rootEntityDef, entityDef: this.entityDef, currentObject: this.currentObject, parentId: parentId, object: undefined});
						
					}
				})
			)
		});
	}
}