import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SelectListitem } from "app/common/models/models";
import { FieldDefinition, FieldType, ValidatorType } from "app/models/entities.model";

@Component({
    selector: 'entity-field',
    templateUrl: './entity-field.component.html'
})
export class EntityField implements OnInit {
	loaded: boolean = false;
	@Input() fieldDef: FieldDefinition;
	@Input() formGroup: FormGroup;
	@Input() readonly: boolean = false;
	
	possibleValues: SelectListitem[] = [];
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	possibileFileTypes = ['application/pdf'];
	required = false;
	ngOnInit(): void {
		if (this.fieldDef.fieldType == FieldType.MULTIPLE_SELECT || this.fieldDef.fieldType == FieldType.SINGLE_SELECT) {
			if (this.fieldDef.possibleValues) {
				this.possibleValues = this.fieldDef.possibleValues.map((value) => { return new SelectListitem(value,value)});
			}
		}
		
		this.fieldDef.validators.forEach((v) => {
			this.required = this.required || (v.validatorType == ValidatorType.REQUIRED);
		})
		this.loaded = true;
	}
}