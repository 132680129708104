import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectListitem } from 'app/common/models/models';
import { forkJoin } from 'rxjs';

import { iconCancel, iconSave } from "app/common/utils/icons.utils";
import { DOMAINS_CONFIG } from 'app/services/restaurant.service';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from 'app/services/auth.service';
import { ProfileService } from 'app/services/profile.service';
import { ACR, ACRService } from 'app/services/acr.service';
import { TranslateService } from '@ngx-translate/core';

export class EntityDescription {
	entityName: string;
	id: any;
	entityDomain: DOMAINS_CONFIG;
}
@Component({
    selector: 'add-acr-to-entity',
    templateUrl: './add-acr-to-entity.modal.html'
})

export class AddACRToEntityModal  implements OnInit {

	iconCancel = iconCancel;
	iconSave = iconSave;

	acrForm: FormGroup;

	public data: EntityDescription;
	
	public get entityName() {
		if (this.data) return this.data.entityName;
		return "";
	}
	public get title() {
		if (this.data) return this._translate.instant("user-profile.sharingOfLabel") + " " + this.data.entityName;
		return "";
	}

	constructor(
		public activeModal: NgbActiveModal,
		private _translate: TranslateService,
		private _fb: FormBuilder,
		private _profileService: ProfileService,
		private _arcService: ACRService) {
		
    }

	currentLinkedUserIds: string[] = [];
	selectUsersItems: SelectListitem[] = [];
	originalAcr: ACR[] = [];

	ngOnInit() {

		forkJoin({
			acr: this._arcService.getACRByEntityId(this.data.id),
			users: this._profileService.getAll()
		})
		.subscribe((result) => {
			if (result.acr) {
				this.currentLinkedUserIds = result.acr.map((acr) => { return acr.userId}) || [];
				this.originalAcr = result.acr;
			}
			if (result.users) {
				this.selectUsersItems = result.users.map(m => new SelectListitem(m.userId, m.firstName + " " + m.lastName + " (" + m.email+ ")"));
			}
			this.initForm(this.currentLinkedUserIds);
		})
	}

   

	

	private initForm = (currentUsers) => {
		this.acrForm = this._fb.group({
			users: [currentUsers]
		})
	}
	
	public close = (): void => {
		this.activeModal.close(false);
    }

    public save() {   
		let acrToDelete: ACR[] = [];
		let acrToSave: ACR[] = [];

		acrToDelete = this.originalAcr.filter((acr) => {
			return this.acrForm.value.users.indexOf(acr.userId) < 0;
		})

		let userToSave: string[] = this.acrForm.value.users.filter((ids) => {
			return this.currentLinkedUserIds.indexOf(ids) < 0;
		})
		acrToSave = userToSave.map((userId) => {
			let acr = new ACR();
			acr.entityDomain= this.data.entityDomain;
			acr.entityId= this.data.id;
			acr.entityName= this.data.entityName;
			acr.userId= userId;
			return acr;
		})
		
		this.activeModal.close(true);
	
		
		acrToSave.forEach((acr) => {
			this._arcService.saveOrUpdateACR(acr).subscribe((result) => {
			})
		})

		acrToDelete.forEach((acr) => {
			this._arcService.deleteACR(acr).subscribe((result) => {
			})
		})

    }
}


