import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Roles, UserProfile } from '../models/profile.models';
import { HttpService } from './http.service';
import jwtDecode from "jwt-decode";
import { environment } from 'environments/environment';
import moment from 'moment'
import { Company, EntityDefinition } from 'app/models/entities.model';
import { SwitchDataLakeService } from './switch-data-lake.service';

export var CompanyService_DOMAINS_CONFIG = "PORTAL";
export var CompanyService_CONFIG_DOMAIN = "ENTITY_DEFINITION";

@Injectable()
export class EntityConfigurationService {
	private baseUrl = environment.services.apiBasePath + "/" + CompanyService_DOMAINS_CONFIG + "/"+CompanyService_CONFIG_DOMAIN;
	
	constructor(private _httpService: HttpService, private _dataLake: SwitchDataLakeService) {
	}
	public getAll = (fieldsName?: string[]): Observable<EntityDefinition[]> => {
		let queryParams = ""
		if (fieldsName && fieldsName.length>0) {
			queryParams = "?";
			fieldsName.forEach(field => {
				queryParams += "fields="+field+ "&";
			})
		}

		return this._httpService.get(this.baseUrl + queryParams, "json");
	}

	// public getMyCompany = (profile: UserProfile): Observable<Company> => {
	// 	if (profile && profile.company)
	// 		return this.getCompany(profile.company.company);
	// 	return of(null);
	// }

	public getById = (id: any): Observable<EntityDefinition> => {
		let url = this.baseUrl + "/" + id;
		return this._httpService.get(url);
	}
	public getByField = (fieldId: string, value: any): Observable<EntityDefinition> => {
		let url = this.baseUrl + "/" + fieldId + "/" + value;
		return this._httpService.get(url);
	}
	public delete = (id: any): Observable<EntityDefinition> => {
		let url = this.baseUrl + "/" + id;
		return this._httpService.delete(url);
	}

	public saveOrUpdate = (entity: EntityDefinition): Observable<EntityDefinition> => {
		let url = this.baseUrl;
		entity.lastUpdateDate = moment().format("DD-MM-YYYY hh:mm");
		if (entity.id)
			return this._httpService.put(url + "/"+entity.id , entity,"json");
		return this._httpService.post(url,entity,"json")
	}
}