import { OnInit, Component } from "@angular/core";
import { DataTableColumn } from './types';
import { DatetimeUtils } from '../../utils/datetime.utils';
import { TranslateService } from '@ngx-translate/core';
import { formatNumber } from '@angular/common';
import { CultureService } from '../../services/culture.service';
import { environment } from "environments/environment";

// @dynamic
@Component({
    template: ''
})
export abstract class BaseDataTableCellTemplateComponent implements OnInit {
    ngOnInit(): void {
        this.init()
    }
    public col: DataTableColumn;
    public row: any;
    public getCellValue = (): any => {
        return this.row[this.col.name];
    }
    public init = (): void => { };
}
@Component({
    template: ''
})

export abstract class BaseDataTableFixedCellTemplateComponent extends BaseDataTableCellTemplateComponent {
    public value: string = null;

}

@Component({
    selector: 'data-table-cell',
    templateUrl: './data-table-cell-template.component.html',
})
export class DataTableStringCellTemplateComponent extends BaseDataTableFixedCellTemplateComponent {

    public init = (): void => {
        this.value = this.getCellValue();
    }
}

@Component({
    selector: 'data-table-cell',
    templateUrl: './data-table-cell-template.component.html',
})
export class DataTableDateCellTemplateComponent extends BaseDataTableFixedCellTemplateComponent {

    public init = (): void => {
        this.value = DatetimeUtils.toDate(this.getCellValue());
    }
}

@Component({
    selector: 'data-table-cell',
    templateUrl: './data-table-cell-template.component.html',
})
export class DataTableDateTimeCellTemplateComponent extends BaseDataTableFixedCellTemplateComponent {

    public init = (): void => {
        this.value = DatetimeUtils.toDateTime(this.getCellValue());
    }
}

@Component({
    selector: 'data-table-cell',
    templateUrl: './data-table-cell-template.component.html',
})
export class DataTableTimeCellTemplateComponent extends BaseDataTableFixedCellTemplateComponent {

    public init = (): void => {
        this.value = DatetimeUtils.toTime(this.getCellValue());
    }
}

@Component({
    selector: 'data-table-cell',
	template: `
		<span  [ngClass]="{'badge-success': (getCellValue()), 'badge-danger': (!getCellValue()), 'badge-pill': true}">{{value | translate}}</span>
	`
	
})
export class DataTableBooleanCellTemplateComponent extends BaseDataTableFixedCellTemplateComponent {
    constructor() {
        super()
    }
    public init = (): void => {
		this.value = this.getCellValue() ? "common.yes" : "common.no";	
    }
}

@Component({
    selector: 'data-table-cell',
    templateUrl: './data-table-cell-template.component.html',
})
export class DataTableIntCellTemplateComponent extends BaseDataTableFixedCellTemplateComponent {
    public init = (): void => {
        if (this.getCellValue() != null) {
            this.value = Math.trunc(this.getCellValue()).toString();
        }
    }
}

@Component({
    selector: 'data-table-cell',
    templateUrl: './data-table-cell-template.component.html',
})
export class DataTableNumberCellTemplateComponent extends BaseDataTableFixedCellTemplateComponent {
    constructor(private _cultureService: CultureService) {
        super()
    }

    public init = (): void => {
        if (this.getCellValue() != null) {
            let numOfDecimals = this.col.metadata.numOfDecimals;
            let digitsInfo: string = `1.${numOfDecimals != null ? numOfDecimals : 0}-${numOfDecimals != null ? numOfDecimals : 999}`;
            this.value = formatNumber(this.getCellValue(), this._cultureService.getCurrentCulture().code, digitsInfo);
        }
    }
}

@Component({
    selector: 'data-table-cell',
    templateUrl: './data-table-cell-template.component.html',
})
export class DataTableCurrencyCellTemplateComponent extends BaseDataTableFixedCellTemplateComponent {
    constructor(private _cultureService: CultureService) {
        super()
    }

    public init = (): void => {
        if (this.getCellValue() != null) {
            let digitsInfo: string = "1.2-2";
            this.value = formatNumber(this.getCellValue(), this._cultureService.getCurrentCulture().code, digitsInfo);
        }
    }
}


@Component({
    selector: 'data-table-cell',
    templateUrl: './data-table-cell-template.component.html',
})
export class DataTableEnumCellTemplateComponent extends BaseDataTableFixedCellTemplateComponent {
    constructor(private _translateService: TranslateService) {
        super()
    }
    public init = (): void => {
        if (this.getCellValue()) {
            this.value = `enums.${this.col.metadata.enumName}.${this.getCellValue()}`;
        }
    }
}


@Component({
    selector: 'data-table-click-cell',
    templateUrl: './data-table-click-cell-template.component.html',
    styleUrls: ['data-table-click-cell-template.component.scss']
})
export class DataTableClickCellTemplateComponent extends BaseDataTableFixedCellTemplateComponent {
    public clickAction: (row) => void;
    constructor() {
        super()
    }
    public init = (): void => {
        this.value = this.getCellValue();
        this.clickAction = this.col.metadata.clickAction;
    }
}


@Component({
    selector: 'data-table-list-cell',
    templateUrl: './data-table-list-cell-template.component.html',
	styles: [`
		.margin-right {
			margin-right: 5px;
		}		
  `]
})

export class DataTableArrayCellTemplateComponent extends BaseDataTableFixedCellTemplateComponent {
	public elementsValues = [];
    constructor() {
        super()
    }
    public init = (): void => {
		let value = (this.getCellValue())?this.getCellValue():[];
		let prefix = "";
		if (this.col.metadata.containsEnums) prefix = "enums."+ this.col.metadata.enumName + ".";

		if (this.col.metadata.fieldName) {
			this.value = value.map(v => v[""+this.col.metadata.fieldName]).join(", ");
			this.elementsValues = value.map(v => prefix+v[this.col.metadata.fieldName]);
		}
		else {
			this.value = value.join(", ");
			this.elementsValues = value.map(v => prefix+v);
		}
		console.log("valore",this.value);
		
       
    }
}
@Component({
    selector: 'image-data-table-cell',
	template: `
		<div *ngIf="!value" class="avatar mr-1 ml-0 bg-primary">
			<div class="avatar-content">{{ name | shortName }}</div>
		</div>
		<img *ngIf="value" class="rounded-circle mr-1" [src]="value" height="32" width="32">
	`
})
export class DataTableImageCellTemplateComponent extends BaseDataTableFixedCellTemplateComponent {
    name: string;
	constructor() {
        super()
    }
    public init = (): void => {
		this.value = this.getCellValue();
		this.name = 
			(((this.col.metadata.fieldName)? this.row[this.col.metadata.fieldName]: "") +
			" " +
			((this.col.metadata.fieldSurname)? this.row[this.col.metadata.fieldSurname]: "")).trim();
    }
}

@Component({
    selector: 'avatar-user-data-table-cell',
	template: `
	<div class="d-flex align-items-center">
		<div class="avatar mr-1 ml-0 bg-primary">
			<div class="avatar-content">{{ name | shortName }}</div>
		</div>
		<div class="cell-line-height">
			<p class="font-medium-1 line-height-1 mb-0">{{ value | translate }}</p>
			<span class="text-muted font-small-2">
			{{ name }}</span>
		</div>
	</div>

	`
	
})
export class DataTableUserAvatarCellTemplateComponent extends BaseDataTableFixedCellTemplateComponent {
    name: string;
	constructor() {
        super()
    }
    public init = (): void => {
		this.value = this.getCellValue();
		this.name = 
			(((this.col.metadata.fieldName)? this.row[this.col.metadata.fieldName]: "") +
			" " +
			((this.col.metadata.fieldSurname)? this.row[this.col.metadata.fieldSurname]: "")).trim();
    }
}


@Component({
    selector: 'avatar-user-data-table-cell',
	template: `
	<div class="d-flex align-items-center">
		<secured-img [src]="value" [getContentImage]="getContentImage"  [width]="'30px'"></secured-img>
	</div>

	`
	
})
export class DataTableIconImageCellTemplateComponent extends BaseDataTableFixedCellTemplateComponent {
    getContentImage = false;
	public init = (): void => {
		if (this.row.mediaId) {
			this.getContentImage = true;
			this.value = this.row.mediaId;
		}
		else {
			this.value = this.getCellValue();
		}
    }
}