import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { iconCancel, iconSave } from "app/common/utils/icons.utils";
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'wallet-import-modal',
    templateUrl: './wallet-import-modal.page.html'
})
export class WalletImportModalForm implements OnInit {
	iconCancel = iconCancel;
	iconSave = iconSave;
	public entityForm: FormGroup = null;	
	data: {
	};

	get formValid() {
		return this.entityForm && this.entityForm.valid;
	}

	ngOnInit() {
		this.initForm();
	}
	
    constructor(
		private activeModal: NgbActiveModal,
		private _fb: FormBuilder
		) {
			
    }

	@HostListener('window:keyup.esc') onKeyUp() {
		this.close();
	}
	
	private initForm = () => {
		let entityFormDef: any = {
			pubblicKey: [null, CommonValidators.required],
			privateKey: [null, CommonValidators.required]
			
		};
		this.entityForm = this._fb.group(entityFormDef);
	}


    public save() {
        if (this.entityForm.valid) {
			this.activeModal.close(this.entityForm.value);
        }
    }

	
	public get canSaveForm(): boolean {
		return this.entityForm && this.entityForm.valid;
	}

	public close = (): void => {
		console.log("ON close....");
		
		this.activeModal.close(false);
    }



}


