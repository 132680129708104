import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { iconAdd, iconCancel } from "app/common/utils/icons.utils";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectListitem } from 'app/common/models/models';
import { LandingPageDefinition } from 'app/models/landing-page.model';

@Component({
    selector: 'abs-entity-field-selector-modal',
    templateUrl: './abs-field-selector-modal.page.html'
})
export class AbstractEntityFieldSelectorModalForm implements OnInit {
	iconCancel = iconCancel;
	iconAdd = iconAdd;
	public entityForm: FormGroup = null;	
	data: {
		placeholders: SelectListitem[]
	};
	

	ngOnInit() {
		this.initForm();
		
	}

    constructor(
		private activeModal: NgbActiveModal,
		private _fb: FormBuilder) {
			
    }

	private initForm = (initData?: LandingPageDefinition) => {
		 let entityFormDef: any = {
			placeholder: [null, Validators.required]
		};

		this.entityForm = this._fb.group(entityFormDef);
	}

	
	public get canSaveForm(): boolean {
		return this.entityForm.valid;
	}
    public save() {
		this.activeModal.close(this.entityForm.value.placeholder);
    }
	public close = (): void => {
		this.activeModal.close(null);
    }
}


