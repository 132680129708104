import { Component } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileService } from 'app/services/profile.service';
import { ModalService } from 'app/common/services/modal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserProfile } from 'app/models/profile.models';
import { ToastService } from 'app/common/services/toasts.service';
import { ACR, ACRService, ARCPermission } from 'app/services/acr.service';
import { BaseListTablePage } from 'app/common/components/pages/base-list-table.page';
import { DataTableAction, DataTableColumn, ListTableManager } from 'app/common/components/table/types';
import { ActivatedRoute } from '@angular/router';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { TranslateService } from '@ngx-translate/core';

class Filters{
}

@Component({
    selector: 'abstract-entity-permission-modal',
    templateUrl: './abstract-entity-permission.modal.html'
})
export class AbstractEntityPermissionModalForm extends BaseListTablePage<UserProfile>  {
	currentDomain;
	domainPath;
	downloadExcelEnabled = false;
	deleteEntityEnabled = false;
	numberOfAvailableItems: number = 0;
	public tableColumns: DataTableColumn[] = [];
	public tableActions: DataTableAction[] = [];
	public filters: Filters = new Filters();
	title;
	permittedUsers = [];
	data: {
		entityDomain?: any,
		entityId?: any,
		entityName?: any
	} = {};

	constructor(
		private _arcService: ACRService,
		private _activeModal: NgbActiveModal,
		private _toastService: ToastService,
		_activatedRoute: ActivatedRoute,
		// private _titleService: Title, 
		private _userProfileService: ProfileService,
		private _translateService: TranslateService,
		private _modalService: ModalService) {
		super(_activatedRoute, true);
	}

	initialize = (): void => {
		this.title = `${this._translateService.instant("abstract-entity.permission.modalTitle")} ${this.data.entityName}`;
		this.createColumms();
		this.createActions();
		// this._titleService.setTitle("Gestione utenze");
		this.init();
	}

	currentLinkedUserIds = [];
	enabledAcr: ACR[] = [];
	availableUsers = [];

	userAddRequest = (event) => {
		if (event) {
			let acr: ACR = new ACR();
			acr.userId = event.user.userId;
			acr.permission = event.permission;

			acr.entityDomain = this.data.entityDomain;
			acr.entityId = this.data.entityId;
			acr.entityName = this.data.entityName;

			this._arcService.saveOrUpdateACR(acr).subscribe((result) => {
				this.enabledAcr.push(result);
				this._toastService.showSuccess(this._translateService.instant("abstract-entity.permission.addSuccessMessage"));
				this.availableUsers = this.availableUsers.filter((u) => {
					return u.userId != event.user.userId;
				})
				this.currentLinkedUserIds.push(event.user.userId);
				let userToAdd:any = {};
				Object.assign(userToAdd,event.user);
				userToAdd.permission = event.permission;
				this.permittedUsers.push(userToAdd);
				
				this.dataTableManager.startReload();
			})
			
			

			
		}
		
	}
	private init = () => {
		forkJoin({
			acr: this._arcService.getACRByEntityId(this.data.entityId),
			users: this._userProfileService.getAll(["id", "userId", "firstName", "lastName", "email"]).pipe(map((r) => {if (r) this.numberOfAvailableItems = r.length; return r.map((u) => {(<any>u).name=u.firstName + " " + u.lastName; return u});}))
		})
		.subscribe((result) => {
			if (result.acr) {
				this.currentLinkedUserIds = result.acr.map((acr) => { return acr.userId});
				this.enabledAcr = result.acr;
			}
			if (result.users) {
				this.permittedUsers = result.users.filter((user) => {
					return this.currentLinkedUserIds.lastIndexOf(user.userId) >= 0;
				})
				.map((user) => {
					this.enabledAcr.forEach((acr) => {
						if (user.userId == acr.userId) {
							(<any>user).permission = acr.permission;
						}
					})
					return user;
				})

				this.availableUsers = result.users.filter((user) => {
					return this.currentLinkedUserIds.lastIndexOf(user.userId) < 0
				})
				this.dataTableManager.startReload();
			}
			
		})
	}
	
	private getUsers = (): Observable<UserProfile[]> => {
		return of(this.permittedUsers);
	}

	protected getDataTableManager = (): ListTableManager<UserProfile> => {
		return new ListTableManager(this.getUsers)
	}

	private createColumms = (): void => {
		let fields = [
			{id: "id", hidden: true},
			{id: "userId", dataTableField: DataTableUtils.createStringColumn("userId", "User ID", true), hidden: true},
			{id: "name", dataTableField: DataTableUtils.createStringColumn("name", "common.name", true), hidden: false},
			{id: "email", dataTableField: DataTableUtils.createStringColumn("email", "formContactLabels.email", true), hidden: false},
			{id: "permission", dataTableField: DataTableUtils.createEnumColumn("permission","abstract-entity.permission.permissionColumnLabel", "ARCPermission", false), hidden: false}
		];
		fields.forEach(element => {
			if (!element.hidden)
				this.tableColumns.push(element.dataTableField);
		});
	}

	private createActions = (): void => {
		let buttonRefresh = new DataTableAction();
		buttonRefresh.funcToInvoke = this.update;
		buttonRefresh.label = "common.revert";
		buttonRefresh.icon = "fas fa-refresh";
		buttonRefresh.enableFunc =  (row) => {
			if (row.permission == ARCPermission.EDITOR) buttonRefresh.label = "abstract-entity.permission.switchToRead";
			else buttonRefresh.label = "abstract-entity.permission.switchToWrite";
			return true;
		};
		this.tableActions.push(buttonRefresh);
		
		let button = new DataTableAction();
		button.funcToInvoke = this.delete;
		button.label = "common.delete";
		button.icon = "ft-trash-2";
		this.tableActions.push(button);

		
		

	}
	
	public update = (row: any): void => {
		let callback = (data) => {
			if (data) {
				let toUpdate: ACR;
				this.enabledAcr.forEach((acr) => {
					if (acr.userId == row.userId) toUpdate = acr;
				})
				if (toUpdate) {
					if (toUpdate.permission == ARCPermission.EDITOR) toUpdate.permission = ARCPermission.VIEWER;
					else toUpdate.permission = ARCPermission.EDITOR;
					
					this._arcService.saveOrUpdateACR(toUpdate).subscribe((result) => {
						this._toastService.showSuccess(this._translateService.instant("abstract-entity.permission.successUpdateMessage"));
						this.permittedUsers = this.permittedUsers.map((u) => {
							let result: any = u;
							if (result.userId == toUpdate.userId) {
								result = {};
								Object.assign(result, u);
								result.permission = toUpdate.permission;
							}
							return result;
						});
						this.dataTableManager.startReload();

					})
					
				}
					
			}
		}
		this._modalService.showConfirm("common.dialogs.alertUpdateItemTitle", "common.dialogs.alertUpdateItemMessage", {
			callback: callback,
			size: "md"
		});
    }

	public delete = (row: any): void => {
		let callback = (data) => {
			if (data) {
				let toRemove: ACR;
				this.enabledAcr.forEach((acr) => {
					if (acr.userId == row.userId) toRemove = acr;
				})
				if (toRemove) {
					this._arcService.deleteACR(toRemove).subscribe((result) => {
						this._toastService.showSuccess(this._translateService.instant("abstract-entity.permission.successRemoveMessage"));
						this.enabledAcr = this.enabledAcr.filter((acr) => {return acr.id == toRemove.id});
						this.availableUsers = this.availableUsers.concat([row]);
						this.currentLinkedUserIds = this.currentLinkedUserIds.filter((e) => {return e != row.userId})
						this.permittedUsers = this.permittedUsers.filter((u) => {return u.userId != row.userId});
						this.dataTableManager.startReload();
					})
					
				}
					
			}
		}
		this._modalService.showConfirm("common.dialogs.alertRemoveItemTitle", "common.dialogs.alertRemoveItemMessage", {
			callback: callback,
			size: "md"
		});
    }
	public close = (): void => {
		this._activeModal.close(false);
    }
}


