import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iconCert, iconSave } from 'app/common/utils/icons.utils';
import { EntityDefinition } from 'app/models/entities.model';

import { ProfileService } from 'app/services/profile.service';
import { Roles, UserProfile } from 'app/models/profile.models';
import { ToastService } from 'app/common/services/toasts.service';
import { EntityConfigurationService } from 'app/services/entity-configuration.service';
import { StoredObjectService } from 'app/services/stored-object.service';
import { Location } from '@angular/common';
import { BaseComponent } from 'app/common/components/base.component';
import { ModalOptions } from 'app/common/models/models';
import { ModalService } from 'app/common/services/modal.service';
import { AddNewEntityModalEditor } from '../add-entity-modal/add-new-entity.modal';
import { DataReloader } from '../../components/list-object-preview/list-object-preview.component';
import { forkJoin } from 'rxjs';
import { ACR, ACRService, ARCPermission } from 'app/services/acr.service';


@Component({
    selector: 'entity-home-page',
    templateUrl: './entity-home.page.html'
})
export class EntityHomePage extends BaseComponent implements OnInit {
	userProfile: UserProfile;
	rootAbstractEntityId: EntityDefinition;
	currentEntityDef: EntityDefinition;
	pageLoaded = false;
	activeTab = "baseEntityDetails";
	valid = false;
	objectIstance: any = null;
	parentIdChain;
	idWhitParent: any;
	historypath: any[] = [];

	iconSave = iconSave;
	iconCertify = iconCert;

	listObjectDataReloaders: any = {};
	userACRs: ACR[] = [];

	canEdit: boolean = false;

	constructor(
		private _activatedRoute: ActivatedRoute,
		private _location: Location,
		private _router: Router, 
		private _userProfileService: ProfileService,
		private _entityConfigurationService: EntityConfigurationService,
		private _storedObjectService: StoredObjectService,
		private _modalService: ModalService,
		private _toastService: ToastService,
		private _acrService: ACRService
		) {
			super();
	}


	//entity-home/:rootAbstractEntityId/:abstractEntityId/:parentIdChain/:id

	private searchEntityDefAndUpdateHistoryPath = (entity: EntityDefinition, entityId): EntityDefinition => {
		if (entity && entity.entityId == entityId) {
			this.historypath.unshift({
				abstractEntityId: entityId,
				rootAbstractEntityId: this.rootAbstractEntityId.entityId,
				entityName: entity.name
			})
			return entity;
		}
		let found: EntityDefinition;
		if (entity.entityChilds) {
			entity.entityChilds.forEach((children) => {
				let hint = this.searchEntityDefAndUpdateHistoryPath(children,entityId);
				if (hint) {
					this.historypath.unshift({
						abstractEntityId: entity.entityId,
						entityName: entity.name,
						rootAbstractEntityId: this.rootAbstractEntityId.entityId
					})
	
					found = hint;
				}
			})	
		}
		return found;
	}

	// private getEntityDef = (entity: EntityDefinition, entityId): EntityDefinition => {
	// 	if (entity && entity.entityId == entityId) {
	// 		return entity;
	// 	}
	// 	let found: EntityDefinition;
	// 	if (entity.entityChilds) {
	// 		entity.entityChilds.forEach((children) => {
	// 			let hint = this.getEntityDef(children,entityId);
	// 			if (hint) {
	// 				found = hint;
	// 			}
	// 		})	
	// 	}
	// 	return found;
	// }

	private resetComponentState = () => {
		this.historypath = [];
		// if (this.walletForm) this.walletForm.patchValue({secret: null});
	}

	ngOnInit() {
		console.log("ngOnInit");
		this.resetComponentState();
		this._userProfileService.getLoggedProfile().subscribe((profile) => {
			
			this.userProfile = profile;
			this.on(
				this._activatedRoute.paramMap.subscribe(qp => {
					console.log("this._activatedRoute.paramMap.", qp);
					
					if (qp && qp.get("rootAbstractEntityId")) {
						this.resetComponentState();
						forkJoin({
							rootAbstractEntityId: this._entityConfigurationService.getByField("entityId",  qp.get("rootAbstractEntityId")),
							userACRs: this._acrService.getACROfProfile(this.userProfile),
			
						}).subscribe((result) => {
							this.userACRs = result.userACRs;
							this.rootAbstractEntityId = result.rootAbstractEntityId;
							
							if (this.isAdmin) {
								this.canEdit = true;
							}
							else {
								this.userACRs.forEach((acr) => {
									if (acr.entityId == this.rootAbstractEntityId .entityId) {
										if (acr.permission == ARCPermission.EDITOR) this.canEdit = true;
										else this.canEdit = false;
									}
								})
							}
							if (qp.get("rootAbstractEntityId") == qp.get("abstractEntityId")) {
								this.currentEntityDef = result.rootAbstractEntityId
							}
							else {
								this.currentEntityDef = this.searchEntityDefAndUpdateHistoryPath(this.rootAbstractEntityId, qp.get("abstractEntityId"));								
							}
							this.parentIdChain = qp.get("parentIdChain");
							this.idWhitParent = this.parentIdChain + "." + qp.get("id");
							let ids = (""+this.parentIdChain).split(".");
							this.historypath = this.historypath.map((element, index) => {
								element["parentIdChain"] = ids.slice(0, index+1).join(".");
								element["objectId"] = ids[index+1];
								return element;
							})
							console.log("history: ", this.historypath);
							if (this.historypath.length > 0) {
								this.historypath.pop();
							}
	
							console.log("rootAbstractEntityId", this.rootAbstractEntityId);
							console.log("currentEntityDef", this.currentEntityDef);
							if (qp.get("id")) {
								this._storedObjectService.getById(this.currentEntityDef,qp.get("id")).subscribe((obj) => {
									this.objectIstance = obj;
									this.pageLoaded = true;
								})
							}
							else {
								this.pageLoaded = true;
							}
						})
					}
					else {
						
					}
				})
			);
			

			
		})
	}

	get isInUpdateState () {
		return this.objectIstance && this.objectIstance.id;
	}

	setActiveTab = (tab) => {
		this.activeTab = tab;
	}

	onObjectUpdate = (update) => {
		if (!this.objectIstance) this.objectIstance={};
		Object.assign(this.objectIstance, update);
	}
	objectValid = (valid) => {
		this.valid = valid;
	}

	setDataLoader(reloader: DataReloader, entityId) {
		this.listObjectDataReloaders[""+entityId] = reloader;
	}
	

	private getHistoryPath = (historypath: any[], entity: EntityDefinition, entityId): EntityDefinition => {
		if (entity && entity.entityId == entityId) {
			historypath.unshift({
				abstractEntityId: entityId,
				rootAbstractEntityId: this.rootAbstractEntityId.entityId,
				entityName: entity.name
			})
			return entity;
		}
		let found: EntityDefinition;
		if (entity.entityChilds) {
			entity.entityChilds.forEach((children) => {
				let hint = this.getHistoryPath(historypath, children,entityId);
				if (hint) {
					historypath.unshift({
						abstractEntityId: entity.entityId,
						entityName: entity.name,
						rootAbstractEntityId: this.rootAbstractEntityId.entityId
					})
					found = hint;
				}
			})	
		}
		return found;
	}

	addNewEntityObject = (children: EntityDefinition) => {
		let modalOption: ModalOptions<any> = {
			size: 'xl',
			callback: (res) => {
				if (res) {
					
					if (this.listObjectDataReloaders[""+children.entityId]) {
						(<DataReloader>this.listObjectDataReloaders[""+children.entityId]).reloadData();
					}
				}
			}
		}
		console.log("this.historypath",this.historypath);
		let newHistory = [];
		this.getHistoryPath(newHistory, this.rootAbstractEntityId, children.entityId);
		newHistory = newHistory.slice(0, newHistory.length-1);
		let parentChain = this.parentIdChain + "." + this.objectIstance.id 
		let ids = (""+parentChain).split(".");
		

		newHistory = newHistory.map((element, index) => {
			element["parentIdChain"] = ids.slice(0, index+1).join(".");
			element["objectId"] = ids[index+1];
			return element;
		})

	
		console.log("newHistory",newHistory);
		
		let data = {
			profile: this.userProfile,
			currentEntityDef: children,
			objectIstance: null,
			parentIdChain: this.parentIdChain + "." + this.objectIstance.id,
			rootAbstractEntity: this.rootAbstractEntityId,
			historypath: newHistory

		}
		this._modalService.show(AddNewEntityModalEditor, data, modalOption);

	}
	editEntity = () => {
		let modalOption: ModalOptions<any> = {
			size: 'xl',
			callback: (res) => {
				if (res) {
					this.objectIstance = {};
					Object.assign(this.objectIstance,res);
				}
			}
		}
		

		let data = {
			profile: this.userProfile,
			currentEntityDef: this.currentEntityDef,
			objectIstance: this.objectIstance,
			parentIdChain: this.parentIdChain,
			rootAbstractEntity: this.rootAbstractEntityId,
			historypath: this.historypath

		}
		this._modalService.show(AddNewEntityModalEditor, data, modalOption);
		
	}

	

	goTo = (path) => {
		this._activatedRoute.paramMap.subscribe(qp => {
			const queryParams = {};
			// queryParams['returnUrl'] = "/user-profile"
			this.pageLoaded = false;
			this.setActiveTab("baseEntityDetails");
			// this._router.navigate(["/entity-home", path.rootAbstractEntityId, path.abstractEntityId, path.parentIdChain, path.id], { queryParams: queryParams, skipLocationChange: true });
			this._router.navigate(["/entity-home", path.rootAbstractEntityId, path.abstractEntityId, path.parentIdChain, path.objectId], { queryParams: queryParams, skipLocationChange: true });
		})
	}

	public goToEntityHome = (event: {rootEntityDef, entityDef, currentObject, parentId, object?: any}) => {
		let navArray = [];
		const queryParams = {};
		if (event.object) {
			navArray = ["/entity-home", event.rootEntityDef.entityId, event.entityDef.entityId, event.parentId, event.object.id]
		}
		else {
			navArray = ["/entity-home", event.rootEntityDef.entityId, event.entityDef.entityId, event.parentId];
		}

		this.pageLoaded = false;
		this.setActiveTab("baseEntityDetails");
		this._router.navigate(navArray, { queryParams: queryParams });

	}

	get isAdmin() {
		return this._userProfileService.isInRole(Roles.ADMIN);
	}



}