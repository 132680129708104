import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { iconAdd, iconSetting, iconTrash } from "app/common/utils/icons.utils";

import { CommonValidators } from "app/common/components/form/validators/common.validator";

import { ModalOptions, SelectListitem } from 'app/common/models/models';
import { ModalService } from 'app/common/services/modal.service';
import { LPButtonLinkType, LPSectionButtonLink, LandingPageSection } from 'app/models/landing-page.model';
import { AbstractEntityFieldSelectorModalForm } from './abs-field-selector-modal.page';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'abs-entity-lp-section-editor-form',
    templateUrl: './abs-lp-section-editor.form.html'
})
export class AbstractEntityLandingPageSectionEditorForm implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	pdfFiles = ['application/pdf'];
	videoMediaTypes = ['video/mp4'];
	iconFieldRemove = iconTrash;
	iconSetting = iconSetting;
	public currentEntityDef: any = {};
	@Input("section") section: LandingPageSection = null;
	@Input("index") index;
	@Output() requestDeleteGroup = new EventEmitter<LandingPageSection>();
	@Output() groupValid = new EventEmitter<boolean>();
	
	public entityForm: FormGroup = null;
	public entityFields: FormArray = null;
	iconAdd = iconAdd;

	ngbAccordion;
	@Input("certFields") certFields: SelectListitem[] = [];

	get groupName() { 
		if (this.entityForm) return this.entityForm.value.title;
		return "Sezione " + this.index;
	}

	fieldToAdd = null;

	buttonLinkType: SelectListitem[] = [
		
	];

    constructor(
		private _translate: TranslateService,
		private _fb: FormBuilder, private ref: ChangeDetectorRef, private _modalService: ModalService) {
			
    }
	ngOnInit() {
		this.buttonLinkType = [
			{id: LPButtonLinkType.Facebook, label: this._translate.instant("enums.LPButtonLinkType." + LPButtonLinkType.Facebook)},
			{id: LPButtonLinkType.Instagram, label: this._translate.instant("enums.LPButtonLinkType." + LPButtonLinkType.Instagram)},
			{id: LPButtonLinkType.shopOnline, label: this._translate.instant("enums.LPButtonLinkType." + LPButtonLinkType.shopOnline)},
			{id: LPButtonLinkType.URL, label: this._translate.instant("enums.LPButtonLinkType." + LPButtonLinkType.URL)},
			{id: LPButtonLinkType.PDF, label: this._translate.instant("enums.LPButtonLinkType." + LPButtonLinkType.PDF)}
		]
		

		this.initForm();
		this.groupValid.emit(this.isValid);
	}
	get isValid() {
		return this.entityForm && this.entityForm.valid;
	}
	
	getButtonLinkType = (field: FormGroup) => {		
		return field.get("type").value ;
	}
	getButtonLinkLabel = (field: FormGroup) => {	
		if (field)
			return "enums.LPButtonLinkType." + field.get("type").value;
		return "";

		// switch (field.get("type").value) {
		// 	case LPButtonLinkType.Facebook:
		// 		return "Link alla pagina Facebook"
		
		// 	case LPButtonLinkType.Instagram:
		// 		return "Link alla pagina Instagram"
		
		// 	case LPButtonLinkType.shopOnline:
		// 		return "Link alla pagina di shop online"

		// 	case LPButtonLinkType.URL:
		// 		return "Url generico"
			
		// 	case LPButtonLinkType.PDF:
		// 		return "File PDF da visualizzare"
				
		// 	default:
		// 		return "";
		// }
	}
	
	private setValidators = ( fieldGroup: FormGroup) => {
		fieldGroup.get("url").clearValidators();
		if (fieldGroup.get("type").value != LPButtonLinkType.PDF) {
			fieldGroup.get("url").setValidators([CommonValidators.required, CommonValidators.websiteFormatValidator()])
		}
		else {
			fieldGroup.get("url").setValidators([CommonValidators.required])
		}
		fieldGroup.get("url").updateValueAndValidity({emitEvent: true});
	}

	addField = (value?: LPSectionButtonLink) => {
		let additional = this._fb.group({
			url: [(value)? value.url: null],
			type: [(value)? value.type: LPButtonLinkType.URL, CommonValidators.required]
		})
		this.setValidators(additional);	
		additional.get("type").valueChanges.subscribe((value) => {
			this.setValidators(additional);	
		})

		if (value) additional.patchValue(value);
		this.entityFields.push(additional);
	}
	
	
	removeField = (index: number) => {
		this.entityFields.removeAt(index);
	}

	private initForm = () => {
		this.entityFields = this._fb.array([]);
		if (this.section && this.section.buttons) {
			this.section.buttons.forEach(element => {
				this.addField(element);
			});
		}
		let groupDefinition = {
			title: [null, CommonValidators.required],
			subtitle: [null],
			description: [null, CommonValidators.required],
			image: [null],
			video: [null],
			linkedFields: [[]],
			buttons: this.entityFields
		};

		this.entityForm = this._fb.group(groupDefinition);
		if (this.section)
			this.entityForm.patchValue(this.section)

		this.entityForm.valueChanges.subscribe((value) => {
			Object.assign(this.section,value);
			this.groupValid.emit(this.isValid);
		})
		this.entityForm.get("linkedFields").valueChanges.subscribe((changes) => {
			if (changes && changes.length > 0) this.fieldToAdd = changes[changes.length-1];
		})
	}

	
	deleteGroup = (event: MouseEvent) => {
		event.preventDefault();
		this.requestDeleteGroup.emit(this.section);
	}

	addCertField = () => {
		this.fieldToAdd = null;
		let modalOption: ModalOptions<string> = {
			size: 'xl',
			callback: (res) => {
				if (res ) {
					this.fieldToAdd = res;
				}
			}
		}
		let data = {
			placeholders: this.certFields
		}
		this._modalService.show(AbstractEntityFieldSelectorModalForm, data, modalOption);
	}

	addCertFieldToSubtitle = () => {
		this.fieldToAdd = null;
		let modalOption: ModalOptions<string> = {
			size: 'xl',
			callback: (res) => {
				if (res ) {
					this.entityForm.get("subtitle").patchValue(
						((this.entityForm.get("subtitle").value)? this.entityForm.get("subtitle").value : "") + " ${"+res+"}"
					)
				}
			}
		}
		let data = {
			placeholders: this.certFields
		}
		this._modalService.show(AbstractEntityFieldSelectorModalForm, data, modalOption);
	}
}