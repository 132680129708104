import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalOptions } from "app/common/models/models";
import { EntityDefinition, FieldType, ValidatorType } from "app/models/entities.model";
import { ModalService } from "app/common/services/modal.service";
import { EntityConfigurationService } from "app/services/entity-configuration.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { environment } from "environments/environment";
import { iconCertPage, iconEdit, iconLink, iconTrash, iconUserShield } from "app/common/utils/icons.utils";

@Component({
    selector: 'object-preview',
    templateUrl: './object-preview.component.html'
})
export class ObjectPreviewComponent implements OnInit {
	
	@Input("entityDef") public entityDef: EntityDefinition;
	@Input("objectIstance") public objectIstance: any;
	@Output("requestShowDetails") requestShowDetails: EventEmitter<any> = new EventEmitter();
	@Output("requestDelete") requestDelete: EventEmitter<any> = new EventEmitter();
	@Output("requestShowCert") requestShowCert: EventEmitter<any> = new EventEmitter();
	@Output("requestEditLP") requestEditLP: EventEmitter<any> = new EventEmitter();
	@Output("requestShowLP") requestShowLP: EventEmitter<any> = new EventEmitter();

	logo = "assets/img/icons/face-icon-0.jpg.png";
	fieldsToShow = [];
	valueToShow = [];
	iconTrash = iconTrash;
	iconEdit = iconEdit;
	iconCert = iconCertPage;
	iconUserShield = iconUserShield;
	iconLink = iconLink;
	constructor(private _fb: FormBuilder) {

	}

	ngOnInit(): void {
		
		this.entityDef.baseInfo.forEach((field) => {
			if (field.fieldType == FieldType.IMAGE) {
				this.logo = environment.services.mediaContentsBasePath + "/" + this.objectIstance[field.fieldLabel];

			}
			else {
				let type;
				let valueToShow;
				if (field.fieldType == FieldType.DATE) {type=FieldType.DATE; valueToShow=this.objectIstance[field.fieldLabel];}
				else if (field.fieldType == FieldType.DECIMAL) {type=FieldType.DECIMAL; valueToShow=this.objectIstance[field.fieldLabel];}
				else if (field.fieldType == FieldType.INTEGER) {type=FieldType.INTEGER; valueToShow=this.objectIstance[field.fieldLabel];}
				else if (field.fieldType == FieldType.MULTIPLE_SELECT) {type=FieldType.MULTIPLE_SELECT; valueToShow=(this.objectIstance[field.fieldLabel])?(<any[]>this.objectIstance[field.fieldLabel]).join(", "): "";}
				else {type=FieldType.STRING; valueToShow=this.objectIstance[field.fieldLabel];}
				this.fieldsToShow.push({key: field.fieldLabel, type: type});
				this.valueToShow.push(valueToShow);
			}
		})
	}
	certAvailable = () => {
		return this.objectIstance.certified == true;
	}

	canShowLPUrl = () => {
		return this.certAvailable() && this.objectIstance.hasLPDefinition;
	}

	showDetails() {this.requestShowDetails.emit(true);}
	delete() {this.requestDelete.emit(true);}
	showCert() {this.requestShowCert.emit(true);}
	editLp() {this.requestEditLP.emit(true);}
	showLp() {this.requestShowLP.emit(true);}
}