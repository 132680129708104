import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonValidators } from 'app/common/components/form/validators/common.validator';
import { ListTableManager } from 'app/common/components/table/types';
import { ModalOptions, SelectListitem } from 'app/common/models/models';
import { ModalService } from 'app/common/services/modal.service';
import { ToastService } from 'app/common/services/toasts.service';
import { provinces } from 'app/common/utils/city.utils';
import { iconAdd, iconCertPage, iconEdit, iconElementView, iconLink, iconQRCode, iconSetting, iconTrash, iconUserShield } from 'app/common/utils/icons.utils';
import { CertificatePageDefinition, Company, EntityDefinition, Product } from 'app/models/entities.model';
import { Roles, UserProfile } from 'app/models/profile.models';
import { ACR, ACRService } from 'app/services/acr.service';
import { AuthService } from 'app/services/auth.service';
import { CompanyService } from 'app/services/company.service';
import { ProductService } from 'app/services/product.service';
import { ProfileService } from 'app/services/profile.service';
import { URLUtils } from 'app/services/url-utils';
import { environment } from 'environments/environment';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EditEntityModalForm } from '../entity-editor/edit-entity-modal.page';
import { EditBaseProductModalForm } from '../product-editor/edit-base-product-modal.page';
import { CertificateModalForm } from '../certificate-page-editor/certificate-page-editor-modal.page';
import { SimpleQRCodeComponent } from 'app/common/components/modals/simple-qrcode.component';
import { AddEntityModalForm } from '../add-entity-modal/add-entity-modal.page';
import { LandingPageDefinition } from 'app/models/landing-page.model';
import { LandingPageModalForm } from '../landing-page-editor/landing-page-editor-modal.page';
import { AgroecologyQuetionnaireModalPage } from '../agroecology-questionnaire/agroecology-questionnaire.modal';
import { SurveyProducersModalPage } from '../survey-producers/survey-producers.modal';

@Component({
	selector: 'company-profile-page',
	templateUrl: './company-profile.page.html'
})

export class CompanyProfilePage implements OnInit {
	pageLoaded = false;
	userProfile: UserProfile;
	enabledCompany: Company;
	formGroup: FormGroup;
	products: Product[] = [];
	iconDelete = iconTrash;
	iconEdit = iconEdit;
	iconList = iconElementView;
	iconLink = iconLink;
	iconCertPage = iconCertPage;
	iconAdd = iconAdd;
	iconSetting = iconSetting;
	iconQRCode = iconQRCode;
	iconUserShield = iconUserShield;
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	activeTab = "general";
	provinces: SelectListitem[] = provinces.map((province) => {return {id:province.nome, label: province.nome}});
	//product list data
	public dataTableManager: ListTableManager<Product>[] = [];

	searchForm: FormGroup;

	
	constructor(
		private _activatedRoute: ActivatedRoute,
		private _router: Router, 
		private _userProfileService: ProfileService,
		private _fb: FormBuilder,
		private _authService: AuthService,
		private _companyService: CompanyService,
		private _acrService: ACRService,
		private _modalService: ModalService,
		private _productService: ProductService,
		private _toastService: ToastService,
		) {

			this.searchForm = this._fb.group({
				searchText: [""]
			})
	}

	private initDashboard = (profile: UserProfile, acrs: ACR[]) => {
	}
	
	initProduct = () => {
		this._productService.getAll(this.enabledCompany.companyId).subscribe((products) => {
			this.products = products;
		})
	}
	ngOnInit() {
		this._userProfileService.getLoggedProfile().subscribe((result) => {
			this.userProfile = result;

			this._activatedRoute.paramMap.subscribe(qp => {
				if (qp && qp.get("companyId")) {

					this._companyService.getCompanyByField("companyId", qp.get("companyId")).subscribe((company) => {
						if (company) {
							this.enabledCompany = company;
							if (this.enabledCompany.surveyProducer) {
								let d = [];
								for (let i=1; i < 16; i++) {
									d.push(this.enabledCompany.surveyProducer["q"+i]);
								}
								this.barChartOptions.series = [{
									data: d
								}],
								
								this.surveyProducer = this.enabledCompany.surveyProducer;
							}
							
							if (this.enabledCompany.agroecologyQuetionnaire) {
								// this.radarChartLabels = this.makeRadarChartLabels(this.enabledCompany.agroecologyQuetionnaire);
								// this.radarChartData = this.makeRadarChartData(this.enabledCompany.agroecologyQuetionnaire);
								this.radarChartOption.labels = this.makeRadarChartLabels(this.enabledCompany.agroecologyQuetionnaire);
								this.radarChartOption.series = this.makeRadarChartData(this.enabledCompany.agroecologyQuetionnaire);
								this.agroecologyQuetionnaire = this.enabledCompany.agroecologyQuetionnaire;
							}
							

							this.initForm();
							this.formGroup.patchValue(this.enabledCompany);
							this.initProduct();
						}
						else {
							this.initForm();
						}
						this.pageLoaded = true;
					},
					(error) => {
						this.initForm();
						this.pageLoaded = true;
					})
				}
				else {
					this.initForm();
					this.pageLoaded = true;
				}
			})

			
		})
	}

	get isAdmin() {
		return this._userProfileService.isInRole(Roles.ADMIN);
	}
	get isSuperAdmin() {
		return this.isAdmin;
	}
	get isLpEditor() {
		return this._userProfileService.isInRole(Roles.LP_EDITOR);
	}
	public goToEnabledDashboard = ($event: MouseEvent, navigationPath): void => {
		$event.preventDefault();
		$event.stopPropagation();

		this._activatedRoute.paramMap.subscribe(qp => {
			const queryParams = {};
			queryParams['returnUrl'] = "/user-profile"
			this._router.navigate(navigationPath, { queryParams: queryParams });
		})
	}

	public showLPQRCode = () => {
		let modalOption: ModalOptions<boolean> = {
			size: 'md',
			callback: (data) => {}
		}
		let title = "QRcode landing page";
		
		if (this.enabledCompany) {
			this._modalService.show(SimpleQRCodeComponent, {url: this.enabledCompany.companyLandingPape, title: title}, modalOption);
		}
	}
	
	
	public get profileImage() {
		if (this.enabledCompany && this.enabledCompany.logo) return environment.services.mediaContentsBasePath + "/" + this.enabledCompany.logo;
		if (this.userProfile && this.userProfile.avatarId)
			return environment.services.mediaContentsBasePath + "/" + this.userProfile.avatarId;
		return "assets/img/icons/face-icon-0.jpg.png"
	}

	public get firstName() {
		if (this.userProfile && this.userProfile.firstName)
			return this.userProfile.firstName;
	}
	public get lastName() {
		if (this.userProfile && this.userProfile.lastName)
			return this.userProfile.lastName;
	}
	public get hasCompanyLandingPage() {
		return (this.enabledCompany && this.enabledCompany.companyLandingPape);
	}
	

	private initForm = (): void => {
		let patter = /^[a-z0-9.,& \-]+$/i;
		
		this.formGroup = this._fb.group({
			id: [null],
			companyId: [null, Validators.required],
			piva: [null, Validators.required],
			logo:[null],
			name:  [null, [
				Validators.required,
				CommonValidators.patternMatchingValidator(patter,"Il nome deve essere composto solo di lettere numeri e simboli - . , &"),
				CommonValidators.noWhiteSpaceAtStardAndEnd("non deve contenere spazi all'inizio ed alla fine del nome")
			]],
			address:  [null, Validators.required],
			cap: [null, Validators.required],
			city: [null, Validators.required],
			province:  [null],
			coordinates: [null],
			email: [null, [CommonValidators.emailFormatValidator]],
			phoneNumber: [null],
			additionalInfo: [null],
			lastUpdateDate: [null],
			companyLandingPape: [null, CommonValidators.websiteFormatValidator()],
			caratteristica_specifica_azienda: [null],
			certificazione_sistema_aziendale: [null],
			superficie_coltivata_azienda: [null]

		});

		if (!this.enabledCompany) {
			this.formGroup.get("name").valueChanges.subscribe((name) => {
				let d = new Date();
				
				if (name)
					this.formGroup.get("companyId").patchValue(
						(""+name).trim().toLowerCase()
						.replaceAll(" ", "")
						.replaceAll(".", "")
						.replaceAll(",", "")
						.replaceAll("-", "")
						.replaceAll("&", "")
						+ "_" + d.getTime()
						);
				console.log("company id: " + this.formGroup.get("companyId").value);
				
			})	
		}
	}

	setActiveTab(tab) {
		this.activeTab = tab;
	}


	onSaveAccountInfo = () => {

	}
	
	get baseInfoValid(): boolean {
		return this.formGroup.valid;
	}
	get showUserPermission(): boolean {
		return this._userProfileService.isInRole(Roles.ADMIN);
	}
	savebaseInfo() {
		if (this.baseInfoValid) {
			let toSave = this.formGroup.value;
			toSave.surveyProducer = this.surveyProducer;
			toSave.agroecologyQuetionnaire = this.agroecologyQuetionnaire;
			if (this.enabledCompany) {
				this._companyService.saveOrUpdate(this.formGroup.value).subscribe((result) => {
					this._toastService.showSuccess("Salvataggio avvenuto con successo");
					this.enabledCompany = result;
					this.formGroup.patchValue(this.enabledCompany);
					this.initProduct();

				})
			}
			else {
				this._companyService.saveOrUpdate(this.formGroup.value).subscribe((result) => {
					this._toastService.showSuccess("Azienda creata con successo");
					this.enabledCompany = result;
					this.formGroup.patchValue(this.enabledCompany);
					this._acrService.linkCompanyToUser(this.userProfile, this.enabledCompany, "ADMIN").subscribe((r) => {
					})
				})
			}
		
		}
	}

	public get showProducts() {
		return this.enabledCompany;
	}
	logout = () => {
		this._authService.logout();
	}

	addProduct = () => {
		let modalOption: ModalOptions<boolean> = {
			size: 'xl',
			callback: (res) => {
				if (res) {
					this.initProduct();
				}
			}
		}
		let data = {
			company: this.enabledCompany,
			userProfile: this.userProfile
		}
		this._modalService.show(EditBaseProductModalForm, data, modalOption);
	}

	getProductImage = (product: Product) => {
		if (product && product.image) return environment.services.mediaContentsBasePath + "/" + product.image;
		return "assets/img/icons/descriptive-content.png"
	}
	editProduct = (product: Product, entity?: EntityDefinition) => {
		
		let modalOption: ModalOptions<boolean> = {
			size: 'xl',
			callback: (res) => {
				if (res) {
					this.initProduct();
				}
			}
		}
		let data = {
			product: product,
			company: this.enabledCompany,
			userProfile: this.userProfile
		}
		this._modalService.show(EditBaseProductModalForm, data, modalOption);
	}

	addNewEntity = (product: Product, entity?: EntityDefinition) => {
		console.log(product, entity);
		
		let modalOption: ModalOptions<boolean> = {
			size: 'xl',
			callback: (res) => {
				if (res) {
					this.initProduct();
				}
			}
		}
		let data = {
			product: product,
			entity: entity,
			company: this.enabledCompany,
			userProfile: this.userProfile,
			readonlyFieldName: true
		}
		this._modalService.show(AddEntityModalForm, data, modalOption);
	}
	

	public get companyName() {
		if (this.enabledCompany && this.enabledCompany.name) return this.enabledCompany.name;
		return "definisci la tua azienda"
	}

	addEntityType = (product: Product) => {
		let modalOption: ModalOptions<boolean> = {
			size: 'xl',
			callback: (res) => {
				
			}
		}
		let data = {
			company: this.enabledCompany,
			userProfile: this.userProfile,
			entity: null,
			product: product
		}
		this._modalService.show(EditEntityModalForm, data, modalOption);
	}

	showEntityList = (product: Product, linkedEntity: EntityDefinition) => {
		const queryParams = {};
		// queryParams['returnUrl'] = this.getReturnUrl();
		this._router.navigate(["/entities-list", this.enabledCompany.companyId, product.productId, linkedEntity.entityId], { queryParams: queryParams });
	}

	editCertificatePage = (product: Product, linkedEntity: EntityDefinition) => {
		let modalOption: ModalOptions<CertificatePageDefinition> = {
			size: 'xl',
			callback: (res) => {
				if (res && res.id) {
					linkedEntity.landingPageDefinitionId = res.id;
					console.log("window.location ", window.location);
					
					// linkedEntity.landingPageUrl =  environment.landingPageBaseUrl + "/"+this.enabledCompany.companyId+"/"+res.id+"/{id}";
					linkedEntity.certVerifyerUrl = environment.certificatePageBaseUrl + "/"+this.enabledCompany.companyId+"/"+res.id+"/{id}#CERT_DATA";
					this._productService.saveOrUpdate(this.enabledCompany, product)
						.pipe(
							catchError(error => {
								this._toastService.showDanger("Si è verificato un errore durante il salvataggio dei dati.");
								return of(null);
							})
						)
						.subscribe(
							(result) => {
								if (result) this._toastService.showSuccess("Salvataggio avvenuto con successo");
							},
							(error) => {
								this._toastService.showDanger("Si è verificato un errore dusante il lalvataggio dei dati");
							}
						)
				}
			}
		}
		let data = {
			company: this.enabledCompany,
			userProfile: this.userProfile,
			entity: linkedEntity,
			product: product,
			pageId: linkedEntity.landingPageDefinitionId
		}
		this._modalService.show(CertificateModalForm, data, modalOption);
	}

	editLandingPage = (product: Product, linkedEntity: EntityDefinition) => {
		let modalOption: ModalOptions<LandingPageDefinition> = {
			size: 'xl',
			callback: (res) => {
				if (res && res.id) {
					linkedEntity.newLandingPageDefinitionId = res.id;
					console.log("window.location ", window.location);
					
					linkedEntity.landingPageUrl =  environment.landingPageBaseUrl + "/"+this.enabledCompany.companyId+"/"+res.id+"/{id}";
					this._productService.saveOrUpdate(this.enabledCompany, product)
						.pipe(
							catchError(error => {
								this._toastService.showDanger("Si è verificato un errore durante il salvataggio dei dati.");
								return of(null);
							})
						)
						.subscribe(
							(result) => {
								if (result) this._toastService.showSuccess("Salvataggio avvenuto con successo");
							},
							(error) => {
								this._toastService.showDanger("Si è verificato un errore dusante il lalvataggio dei dati");
							}
						)
				}
			}
		}
		let data = {
			company: this.enabledCompany,
			userProfile: this.userProfile,
			entity: linkedEntity,
			product: product,
			pageId: linkedEntity.newLandingPageDefinitionId
		}
		this._modalService.show(LandingPageModalForm, data, modalOption);
	}

	editEntity = (product: Product, linkedEntity: EntityDefinition) => {
		let modalOption: ModalOptions<boolean> = {
			size: 'xl',
			callback: (res) => {
				
			}
		}
		let data = {
			company: this.enabledCompany,
			userProfile: this.userProfile,
			entity: linkedEntity,
			product: product
		}
		this._modalService.show(EditEntityModalForm, data, modalOption);
	}

	deleteProduct = (product: Product) => {
		//QR Codel product
		let modalOption: ModalOptions<boolean> = {
			size: 'md',
			callback: (res) => {
				if (res) {
					this._productService.delete(this.enabledCompany.companyId, product.id).subscribe((result) => {
						this.ngOnInit();
					})
				}
			}
		}
		this._modalService.showConfirm("Elimina il prodotto", "Stai eliminando il prodotto '"+product.name+"'. La cancellazione è irreversibile e non avrai più a disposizione i dati.  Procedi alla cancellazione del prodotto?", modalOption);
	};
	deleteEntity = (product: Product, entityIndex) => {
		let modalOption: ModalOptions<boolean> = {
			size: 'md',
			callback: (res) => {
				if (res) {
					product.linkedEntity.splice(entityIndex,1);
		
					// this._productService.saveOrUpdate(this.enabledCompany.companyId, product).subscribe((result) => {})
			
					this._productService.saveOrUpdate(this.enabledCompany, product)
						.pipe(
							catchError(error => {
								this._toastService.showDanger("Si è verificato un errore durante il salvataggio dei dati.");
								return of(null);
							})
						)
						.subscribe(
							(result) => {
								if (result) this._toastService.showSuccess("Salvataggio avvenuto con successo");
							},
							(error) => {
								this._toastService.showDanger("Si è verificato un errore dusante il lalvataggio dei dati");
							}
						)
				}
			}
		}
		this._modalService.showConfirm("Elimina tipo di certificato", "Stai eliminando il tipo di certificato '"+product.linkedEntity[entityIndex].name+"' per "+product.name+". La cancellazione è irreversibile e non avrai più a disposizione i dati. Procedi alla cancellazione del tipo di certificato?", modalOption);
	}

	showCertPage = (product: Product) => {
		let url = URLUtils.getCertPageURL(this.enabledCompany, product);
		window.open(url, "_blank");
	}
	showLandingPage = (product: Product) => {
		let url = URLUtils.getProductLandingPageURL(this.enabledCompany, product);
		window.open(url, "_blank");
	}

	deleteCompany = () => {
		let modalOption: ModalOptions<boolean> = {
			size: 'md',
			callback: (res) => {
				if (res) {
					// this._companyService.delete((this.enabledCompany.id)).subscribe((result) => {
						
					// })
		
					this._acrService.getCompanyACR(this.enabledCompany.companyId).subscribe((acrs: ACR[]) => {
						if (acrs) {
							let toCall = [];
							acrs.forEach((acr) => {
								if (acr.entityId == this.enabledCompany.id) {
									console.log("DELETE ACR " + acr.entityId);
									toCall.push( this._acrService.deleteCompanyACR(acr));
								}

							})
							toCall.push(this._companyService.delete((this.enabledCompany.id)));
							forkJoin(toCall).subscribe((result) => {
								if (result) {
									this._toastService.showSuccess("Eliminazione avvenuta con successo");
									const queryParams = {};
									this._router.navigate(["/user-profile"], { queryParams: queryParams });
								}
							}
							, (error) => {
								this._toastService.showDanger("Si è verificato un errore durante l'eliminazione dell'azienda.");
							}
							)
							
						}
						
					}, (error) => {
						this._toastService.showDanger("Si è verificato un errore durante l'eliminazione dell'azienda.");
					}
					
					)
					
					
					
				}
			}
		}
		this._modalService.showConfirm(
			"Elimina la tua azienda", "Sei sicuro di voler eliminare la tua azienda? La cancellazione è irreversibile e non avrai più a disposizione i dati.", modalOption);

	}

	public get logoImage(): string {
		return environment.domainConfig.companyLogo;
	}


	// radarChartLabels;
  // Radar
	public makeRadarChartLabels(response) {
		if (response) {
			if (response.workers)
				return [
					"THEME 1: Organic production and animal wellbeing",
					"THEME 2: Agrobiodiversity and biodiversity-friendly system",
					"THEME 3: Reduction of food loss and general waste",
					"THEME 4: Regional/local origin, short supply chain, reconnection consumer-producer",
					"THEME 5: Fostering food culture, local traditional knowledge, food sovereignty",
					"THEME 6: Socially-fair production"
				];
			return [
				"THEME 1: Organic production and animal wellbeing",
				"THEME 2: Agrobiodiversity and biodiversity-friendly system",
				"THEME 3: Reduction of food loss and general waste",
				"THEME 4: Regional/local origin, short supply chain, reconnection consumer-producer",
				"THEME 5: Fostering food culture, local traditional knowledge, food sovereignty"
			];
		}
		return [];
	}

	// radarChartData;

	public makeRadarChartData(response) {
		let data: any[];
		if (response) {
			
			data = [
				(response.theme_1_soil_fertility + response.theme_1_pests_and_diseases +  ((response.livestock_farming)? response.theme_1_livestock_system:0)) / ((response.livestock_farming)? 12:8),

				(response.theme_2_polycropping + ((response.livestock_farming)? response.theme_2_livestock_crop:0)) / ((response.livestock_farming)? 8:4),

				response.theme_3_recycle / 4,
				response.theme_4_sell / 4,
				response.theme_5_breeds / 4

			]	
			if (response.workers)
				data.push(response.theme_6_welfare/4);
		}
		// [{
		// 	name: 'Series 1',
		// 	data: [80, 50, 30, 40, 100, 20],
		//   }],

		return [{ data: data, name: 'Agroecology score' }]
	}
	// public radarChartType = 'radar';
	// public radarChartColors = [
	// 	{
	// 	  backgroundColor: "rgba(151, 90, 255, 0.3)",
	// 	  borderColor: "transparent",
	// 	  pointBorderColor: "#975AFF",
	// 	  pointBackgroundColor: "#FFF",
	// 	  pointBorderWidth: 2,
	// 	  pointHoverBorderWidth: 2,
	// 	  pointRadius: 4
	// 	}
	//   ];
	// public radarChartOptions = {
	// 	responsive: true,
	// 	maintainAspectRatio: false,
   	// 	// maintainAspectRatio: true, 
	// 	responsiveAnimationDuration: 500,
	// 	legend: {
	// 	  position: 'top',
	// 	},
	// 	title: {
	// 	  display: false,
	// 	  text: 'Chart.js Radar Chart'
	// 	},
	// 	scale: {
	// 	  reverse: false,
	// 	  ticks: {
	// 		beginAtZero: true,
	// 		max: 1,
    //     	min: 0,
	// 	  }
	// 	}
	// };
	
	agroecologyQuetionnaire: any = null;

	public showAgroecologyQuetionnaire = () => {
		let modalOption: ModalOptions<any> = {
			size: 'lg',
			callback: (res) => {
				if (res) {
					console.log("result: ", res);
					// this.radarChartLabels = this.makeRadarChartLabels(res);
					// console.log("radarChartLabels: ", this.radarChartLabels);
					// this.radarChartData = this.makeRadarChartData(res);
					// console.log("radarChartData: ", this.radarChartData);
					this.radarChartOption.labels = this.makeRadarChartLabels(res);
					console.log("radarChartLabels: ", this.radarChartOption.labels);
					this.radarChartOption.series = this.makeRadarChartData(res);
					console.log("radarChartData: ", this.radarChartOption.series );

					this.agroecologyQuetionnaire = res;
					this._toastService.showSuccess("Please click the 'Save' button to save changes.");

				}
			}
		}
		this._modalService.show(AgroecologyQuetionnaireModalPage, {
			quetionnaire: (this.agroecologyQuetionnaire)? this.agroecologyQuetionnaire: {}
		}, modalOption);
	}

	surveyProducer;

	public showSurveyProducers = () => {
		let modalOption: ModalOptions<any> = {
			size: 'lg',
			callback: (res) => {
				if (res) {
					let d = [];
					for (let i=1; i < 16; i++) {
						d.push(res["q"+i]);
					}
					this.barChartOptions.series = [{
						data: d
					}],
					this.surveyProducer = res;

					this._toastService.showSuccess("Please click the 'Save' button to save changes.");
				}
			}
		}
		this._modalService.show(SurveyProducersModalPage, {
			survey: (this.surveyProducer)? this.surveyProducer: {}
		}, modalOption);
	}

	

	barChartOptions = {
		chart: {
		  height: 350,
		  type: 'bar',
		},
		colors: ["#975AFF", "#F77E17", "#40C057", "#F55252", "#2F8BE6"],
		plotOptions: {
		  bar: {
			horizontal: true,
		  }
		},
		dataLabels: {
		  enabled: false
		},
		series: [{
		  data: []
		}],
		xaxis: {
		  categories: [
			"I am committed to [make/buy/eat/cook/produce] healthy and sustainable [food choices/food products/meals]",
			"It is important for me to [make/buy/eat/cook/produce] healthy and sustainable [food choices/food products/meals]",
			"[Making/eating/producing/providing] healthy and sustainable [products/food choices] makes me feel good",
			"I think most people find it important to consume and produce sustainably",
			"I do not care about [making/producing/buying/cooking] healthy and sustainable [foods/meals/products]",
			"I can decide to [eat/cook/buy/produce] healthy and sustainable foods",
			"Healthy and sustainable [eating/cooking/producing] is easy for me",
			"I understand why it is important to [eat/buy/produce] healthy and sustainable [meals/foods/products]",
			"[Making/eating/cooking/producing/providing] healthy and sustainable food makes me feel part of [my family/friends/co-workers/neighbourhood/]",
			"The [actor network/ team] discuss strategies to improve their working relationship. [REF]",
			"The [actor network/ team members ]come together to resolve problems among members. [REF]",
			"Team members focus on understanding the perspectives of others rather than defending their own specific opinions. [FLEX]",
			"There is freedom to be different and disagree within the [actor network/ team members]. [COL]",
			"New practices related to the Hub activity occur as a result of the diversity of ideas among [actor network/ team members]. [NEW]",
			"As a result of working as a team, services/supports for [target group of the activity] to eat more healthy and sustainable are delivered in new ways. [NEW]"
		],
		  tickAmount: 5,
		  min: 0,
		  max: 4
		}
	  }






	radarChartOption = {
		chart: {
		  height: 400,
		  type: 'radar',
		},
		colors: ["#975AFF", "#F77E17", "#40C057", "#F55252", "#2F8BE6"],
		series: [{
		  name: 'Series 1',
		  data: [],
		}],
		labels: [],
		dataLabels: {
		  style: {
			colors: ["#E6EAEE"]
		  }
		},
		yaxis: {
			tickAmount: 5,
			min: 0,
			max: 1
		}
	}
}