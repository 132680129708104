import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { iconAdd } from "app/common/utils/icons.utils";
import { ARCPermission } from 'app/services/acr.service';
import { SelectListitem } from 'app/common/models/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'user-permission-selection',
    templateUrl: './user-permission-selection.component.html'
})
export class UserPermissionSelectionComponent implements OnInit {
	iconAdd = iconAdd;
	userForm: FormGroup;
	selectUsersItems: SelectListitem[] = [];
	permissions: SelectListitem[] = []

	@Input("availableUsers") set availableUsers(users) {
		this.selectUsersItems = users.map(m => new SelectListitem(m, (<any>m).name + " (" + m.email+ ")"));
	}
	@Output("userAddRequest") userAddRequest: EventEmitter<any> = new EventEmitter();
	
	constructor(private _fb: FormBuilder, private _translate: TranslateService) {	
    }


	ngOnInit() {
		this.permissions = [
			{id: ARCPermission.VIEWER, label: this._translate.instant("enums.ARCPermission."+ARCPermission.VIEWER)},
			{id: ARCPermission.EDITOR, label: this._translate.instant("enums.ARCPermission."+ARCPermission.EDITOR)}
		],
		this.initForm();
	}
	private initForm = () => {
		this.userForm = this._fb.group({
			user: [null],
			permission: [null]
		})
	}
	
	public get isValid() {
		return this.userForm.value.user && this.userForm.value.permission;
	}
	
    public add() {
		let selectedUserPermission = {};
		Object.assign(selectedUserPermission,this.userForm.value);
		this.userForm.patchValue({user: null, permission:null});
		this.userAddRequest.emit(selectedUserPermission);
    }
}
