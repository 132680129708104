import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { iconAdd, iconCancel, iconSave } from "app/common/utils/icons.utils";

import { ProfileService } from 'app/services/profile.service';
import { ModalService } from 'app/common/services/modal.service';
import { EntityDefinition } from 'app/models/entities.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Roles, UserProfile } from 'app/models/profile.models';
import { ToastService } from 'app/common/services/toasts.service';
import { SelectListitem } from 'app/common/models/models';
import { LandingPageDefinition, LandingPageSection } from 'app/models/landing-page.model';
import { LandingPageService } from 'app/services/landing-page.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'abs-entity-landing-page-editor',
    templateUrl: './abs-landing-page-editor-modal.page.html'
})
export class AbstractEntityLandingPageModalForm implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	iconCancel = iconCancel;
	iconSave = iconSave;
	iconAdd = iconAdd;
	public entityForm: FormGroup = null;	
	public groups: LandingPageSection[] = [];
	public groupValidity: boolean[] = [];
	data: {
		entity: EntityDefinition,
		rootAbstractEntityId:  EntityDefinition,
		object: any;
		userProfile: UserProfile,
		pageId: any
		// certificateDefinition: CertificatePageDefinition;
	};
	dataLoaded: boolean = false;
	certFields: SelectListitem[] = [
	];
	
	certificateDefinition: LandingPageDefinition;

	private getHistoryPath = (historypath: any[], entity: EntityDefinition, entityId): EntityDefinition => {
		if (entity && entity.entityId == entityId) {
			historypath.unshift(entity)
			return entity;
		}
		let found: EntityDefinition;
		if (entity.entityChilds) {
			entity.entityChilds.forEach((children) => {
				let hint = this.getHistoryPath(historypath, children,entityId);
				if (hint) {
					historypath.unshift(entity)
					found = hint;
				}
			})	
		}
		return found;
	}

	private entityPath: EntityDefinition[] = [];
	private lpDomainPAth;

	ngOnInit() {
		this.getHistoryPath(this.entityPath,this.data.rootAbstractEntityId, this.data.entity.entityId);
		
		this.entityPath.forEach((entity: EntityDefinition) => {
			entity.baseInfo?.forEach((info) => {
				if (info.fieldToCertify) this.certFields.push({id:  entity.name + " - " + info.fieldLabel, label:  entity.name + " - " + info.fieldLabel})
			})
			entity.groups?.forEach((group) => {
				group.fields.forEach((field) => {
					if (field.fieldToCertify) this.certFields.push({id:  entity.name + " - " + group.groupLabel + " - " + field.fieldLabel, label:  entity.name + " - " + group.groupLabel + " - " + field.fieldLabel});
				})
			})
		})
		this.lpDomainPAth = this.data.entity.entityId + ((this.data.object && this.data.object.id)? "_" +this.data.object.id: ""); 
		if (this.data && this.data.pageId) {
			this._landingPageService.getByField(this.lpDomainPAth, "pageId", this.data.pageId).subscribe((result) => {
				if (result) {
					this.certificateDefinition = result;
					this.initForm(result);
				}
				else {
					this.initForm();
				}
					
				this.dataLoaded = true;
			},
			(error) => {
				this.initForm();
				this.dataLoaded = true;
			}
			)
		}
		else {
			this.initForm();
			this.dataLoaded = true;
		}
		
		

	}

    constructor(
		private activeModal: NgbActiveModal,
		private _toastService: ToastService,
		private _fb: FormBuilder,
		private _landingPageService: LandingPageService,
		private _userProfileService: ProfileService) {
			
    }

	addGroup = (value?: LandingPageSection) => {
		if (!value) {
			value = new LandingPageSection();
			value.buttons = [];
			value.linkedFields = [];
		}
		this.groups.push(value);
		this.groupValidity.push(false);
	}
	removeGroup = (index: number) => {
		this.groups.splice(index,1);
		this.groupValidity.splice(index,1);
	}
	validateGroup = (index: number, valid: boolean) => {
		this.groupValidity[index] = valid;
	}

	private initForm = (initData?: LandingPageDefinition) => {
		
		let entityFormDef: any = {
			id: [(initData)? initData.id: null],
			pageId: [this.data.pageId],
			backgroudColor: [(initData)? initData.backgroudColor: null, Validators.required],
			avatarLogo: [(initData)? initData.avatarLogo: null, Validators.required],
			textColor: [(initData)? initData.textColor: null, Validators.required],
			companyLogo: [(initData)? initData.companyLogo: null],
			
			sectionsLogo: [(initData)? initData.sectionsLogo: null],
			landingPageTitle: [(initData)? initData.landingPageTitle: this.data.entity.name, Validators.required],
			useAvatar: [(initData)? initData.useAvatar: false],
		};

		this.entityForm = this._fb.group(entityFormDef);
	
		if (initData && initData.sections) {
			initData.sections.forEach((group) => {
				this.addGroup(group);
			})
		}
	}

	get isAdmin() {
		return this._userProfileService.isInRole(Roles.ADMIN);
	}

    public save(certData?: boolean) {
		if (!this.certificateDefinition) {
			this.certificateDefinition = new LandingPageDefinition();
		}

		Object.assign(this.certificateDefinition, this.entityForm.value);
		this.certificateDefinition.sections = this.groups;
		
		this._landingPageService.saveOrUpdate(this.lpDomainPAth, this.certificateDefinition).pipe(
			catchError(error => {
				this._toastService.showDanger("lp-editor.saveDataErrorMessage");
				return of(null);
			})
		)
		.subscribe(
			(result) => {
				this.activeModal.close(result);
			},
			(error) => {
				this._toastService.showDanger("lp-editor.saveDataErrorMessage");
			}
		)
    }

	public get canSaveForm(): boolean {
		return this.entityForm.valid && this.groups.length > 0 &&  this.groupValidity.indexOf(false)<0;
	}

	public close = (): void => {
		this.activeModal.close(null);
    }
}


