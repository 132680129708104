import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseListTablePage } from 'app/common/components/pages/base-list-table.page';
import { DataTableAction, DataTableColumn, ListTableManager } from 'app/common/components/table/types';
import { ModalOptions } from 'app/common/models/models';
import { ModalService } from 'app/common/services/modal.service';
import { ToastService } from 'app/common/services/toasts.service';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { ProfileService } from 'app/services/profile.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Roles, UserProfile } from "../../../models/profile.models";
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'user-enabling',
	templateUrl: './user-enabling.page.html'
})

export class UserEnablingList  extends BaseListTablePage<UserProfile> {
	currentDomain;
	domainPath;
	downloadExcelEnabled = false;
	deleteEntityEnabled = false;
	numberOfAvailableItems: number = 0;
	public tableColumns: DataTableColumn[] = [];
	public tableActions: DataTableAction[] = [];
	public filters: Filters = new Filters();
	constructor(
		private _toastService: ToastService,
		private _translate: TranslateService,
		_activatedRoute: ActivatedRoute, private _userProfileService: ProfileService, private _modalService: ModalService) {
		super(_activatedRoute, true);


	}

	

	initialize = (): void => {
		this.createColumms();
		this.createActions();
		
	}

	
	private getUsers = (): Observable<UserProfile[]> => {
		return this._userProfileService.getAll([
			"id",
			"userId",
			"firstName",
			"lastName",
			"email",
			"roles"
		]).pipe(
			
			map((r) => {
				r= r.map((u) => {(<any>u).name=u.firstName + " " + u.lastName; return u});
				if (r) r= r.filter((user) => user.roles && user.roles.lastIndexOf(Roles.DASHBOARD_USER) < 0);
				if (r) this.numberOfAvailableItems = r.length;
				return r;
			}))
				

	}

	protected getDataTableManager = (): ListTableManager<UserProfile> => {
		return new ListTableManager(this.getUsers)
	}

	private createColumms = (): void => {
		let fields = [
			{id: "id", hidden: true},
			{id: "userId", dataTableField: DataTableUtils.createStringColumn("userId", "User ID", true), hidden: true},
			{id: "name", dataTableField: DataTableUtils.createStringColumn("name", "common.name", true), hidden: false},
			{id: "email", dataTableField: DataTableUtils.createStringColumn("email", "formContactLabels.email", true), hidden: false},
		];
		fields.forEach(element => {
			if (!element.hidden)
				this.tableColumns.push(element.dataTableField);
		});
	}

	private createActions = (): void => {
		let button = new DataTableAction();
		button.funcToInvoke = this.edit;
		button.label = "user-profile.enableUserTooltip";
		button.icon = "ft-check";
		this.tableActions.push(button);
	}
	
	public edit = (row: any): void => {
		let callUpdate = (confirm) => {
			console.log("confirm " + confirm);
			
			if (confirm) {
				this._userProfileService.getProfile(row.id).subscribe((result) => {
					if (result && result.roles && result.roles.lastIndexOf(Roles.DASHBOARD_USER) < 0){
						result.roles.push(Roles.DASHBOARD_USER);
						console.log(result);
						
						this._userProfileService.saveOrUpdateOtherProfile(result).subscribe((newUser) => {
							this._toastService.showSuccess(row.name + " " + this._translate.instant("user-profile.userEnabledSuccesMessage"));
							this.dataTableManager.startReload();
						})
					}
					
				})
			}
		}

		let modalOption: ModalOptions<boolean> = {
			size: 'sm',
			callback: callUpdate
		}
		this._modalService.showConfirm("user-profile.userEnableConfirmTitle",  this._translate.instant("user-profile.userEnableConfirmTitle") + " " + row.name + "?", modalOption);

		
		
    }
}


class Filters {
}