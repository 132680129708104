import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SelectListitem } from 'app/common/models/models';
import { Dashboard, Roles, UserProfile } from 'app/models/profile.models';
import { ProfileService } from 'app/services/profile.service';


@Component({
	selector: 'edit-user-roles-form',
	templateUrl: './edit-user-roles.page.html'
})

export class EditUserRoleModal implements OnInit {
	pageLoaded = false;
	userProfile: UserProfile;
	formGroup: FormGroup;
	address: FormGroup;
	contacts: FormGroup;
	companyAddress: FormGroup;
	company: FormGroup;
	walletInfo: FormGroup;
	claimedNFT: FormGroup;

	public userRoles: SelectListitem[] = [];

	privateKey: string;

	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	activeTab = "general";

	public data: any;
	title = "";

	constructor(private _userProfileService: ProfileService, private _fb: FormBuilder, private _activeModal: NgbActiveModal, private _translate: TranslateService) {

		
		
	}

	ngOnInit() {
		let roles = [
			{id: Roles.DASHBOARD_USER, name: this._translate.instant("enums.Roles."+Roles.DASHBOARD_USER)},
			{id: Roles.LP_EDITOR, name: this._translate.instant("enums.Roles."+Roles.LP_EDITOR)},
			{id: Roles.USER_ADMIN, name: this._translate.instant("enums.Roles."+Roles.USER_ADMIN)},
			{id: Roles.ENTITY_ADMIN, name: this._translate.instant("enums.Roles."+Roles.ENTITY_ADMIN)}
		];
		if (this._userProfileService.isInRole(Roles.ADMIN)) 
			roles.push({id: Roles.ADMIN, name: this._translate.instant("enums.Roles."+Roles.ADMIN)});

		this.userRoles = roles.map(m => new SelectListitem(m.id, m.name));


		this._userProfileService.getProfile(this.data.id).subscribe((result) => {

			result.roles = result.roles.filter((role) => {
				return role == Roles.DASHBOARD_USER ||
					role ==  Roles.ADMIN ||
					role == Roles.LP_EDITOR ||
					role == Roles.USER_ADMIN ||
					role == Roles.ENTITY_ADMIN
			})
			this.userProfile = result;
			this.initForm();
			this.formGroup.patchValue(this.userProfile);
			this.title = this._translate.instant("user-profile.enableAccessToLabel") + " " + result.firstName + " " + result.lastName;
			this.pageLoaded = true;
		})
	}



	private initForm = (): void => {

		this.address = this._fb.group({
			address: [null],
			cap: [null],
			city: [null],
			province: [null]
		});
		this.contacts = this._fb.group({
			mobilePhone: [null],
			fixedPhone: [null],
			pec: [null],
			alternativeEmail: [null]
		});


		this.companyAddress = this._fb.group({
			address: [null],
			cap: [null],
			city: [null],
			province: [null]
		});

		this.company = this._fb.group({
			company: [null],
			organization: [null],
			address: this.companyAddress
		});
		this.walletInfo = this._fb.group({
			creationDate: [null],
			pubKey: [null]
		});
		this.claimedNFT = this._fb.group({
			cert_id: [null],
			domain: [null],
			claimedFieldName: [null],
			certIdFieldName: []
			
		});
		this.formGroup = this._fb.group({
			userId: [null],
			avatarId: [null],
			firstName: [null],
			lastName: [null],
			email: [null],
			fiscalCode: [null],

			id: [null],
			lastUpdateDate: [null],
			roles: [[]],
			walletInfo: this.walletInfo,
			claimedNFT: this.claimedNFT,
			company: this.company,
			contacts: this.contacts,
			address: this.address,
			isCompanyProfile: []
		});
	}

	
	save = () => {
		let value = this.formGroup.value;
		value.roles.push(Roles.USER)
		value.roles.push(Roles.DEFAULT)
		value.roles.push(Roles.UMA_AUTH)
		value.roles.push(Roles.OFF_ACCESS)		

		this._userProfileService.saveOrUpdateOtherProfile(value).subscribe((result) => {
			result.roles = result.roles.filter((role) => {
				return role == Roles.DASHBOARD_USER ||
					role ==  Roles.ADMIN ||
					role == Roles.LP_EDITOR ||
					role == Roles.USER_ADMIN ||
					role == Roles.ENTITY_ADMIN
			})
			this.userProfile = result;
			this._activeModal.close(true);
		})

	}

	close = () => {
		this._activeModal.close(false);
	}

}