import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalOptions } from "app/common/models/models";
import { EntityDefinition } from "app/models/entities.model";
import { EditAbstractEntityModalForm } from "./abstract-entity-editor/edit-abstract-entity.modal";
import { ModalService } from "app/common/services/modal.service";
import { CompanyService_CONFIG_DOMAIN, CompanyService_DOMAINS_CONFIG, EntityConfigurationService } from "app/services/entity-configuration.service";
import { ACR, ACRService, ARCPermission } from "app/services/acr.service";
import { ProfileService } from "app/services/profile.service";

@Component({
    selector: 'configurator-component',
    templateUrl: './configurator.component.html'
})
export class AbstractEntityConfiguratorComponent implements OnInit {
	
	@Input("entities") public entities: {
		entity:EntityDefinition,
		canUpdate: boolean
	}[] = [];
	@Output("onEntitiesUpdates") public onEntitiesUpdates: EventEmitter<any> = new EventEmitter<any>();
	constructor(
		private _modalService: ModalService,
		private _entityConfigurationService: EntityConfigurationService,
		private _arcService: ACRService,
		private _profileService: ProfileService
	) {

	}

	ngOnInit(): void {
		// this._entityConfigurationService.getAll().subscribe((result) => {
		// 	this.entities = result;
		// })		
	}


	editEntity = (entityDef?: EntityDefinition) => {
		let modalOption: ModalOptions<EntityDefinition> = {
			size: 'xl',
			callback: (res) => {
				if (res) {
					res.entityId = entityDef.entityId + "_" + res.entityId;
					this._entityConfigurationService.saveOrUpdate(res).subscribe((result) => {
						console.log("result: ", result);
						Object.assign(entityDef, res);
						
					})
					
				}
			}
		}
		let data = {
			entity: entityDef,
		}
		this._modalService.show(EditAbstractEntityModalForm, data, modalOption);
	}

	private addACR = (entity:EntityDefinition) => {
		console.log("ADD ACR",entity);
		
		if (entity) {
			console.log("ADD ACR after IF");
			
			this._profileService.loadUserProfile().subscribe((profile) => {
				console.log("adding ACR for ", profile);
				let acr: ACR = new ACR();
				acr.userId = profile.userId;
				acr.permission = ARCPermission.EDITOR;
				acr.entityDomain = CompanyService_DOMAINS_CONFIG + "/" + CompanyService_CONFIG_DOMAIN;
				acr.entityId = entity.entityId;
				acr.entityName = entity.name;
				this._arcService.saveOrUpdateACR(acr).subscribe((result) => {
					console.log("ACR Saved",result);
					
				})
			})
		}
	}
	addEntity = () => {
		let modalOption: ModalOptions<EntityDefinition> = {
			size: 'xl',
			callback: (res) => {
				
				if (res) {
					res.entityId = res.entityId + "-"+ (new Date()).getTime();
					this._entityConfigurationService.saveOrUpdate(res).subscribe((result) => {
						this.addACR(result);
						this.entities.push({entity: result, canUpdate: true});
						this.onEntitiesUpdates.emit(this.entities)
					})
				}
				
			}
		}
		let data = {
			entity: null,
		}
		this._modalService.show(EditAbstractEntityModalForm, data, modalOption);
	}

	saveEntity = (entity) => {
		this._entityConfigurationService.saveOrUpdate(entity).subscribe((result) => {
			console.log("result: ", result);
			this.onEntitiesUpdates.emit(this.entities)
			
		})
	}

	deleteEntity = (entity: EntityDefinition) => {
		
		this._entityConfigurationService.delete(entity.id).subscribe((res) => {
			this.entities = this.entities.filter((child) => { return entity.entityId != child.entity.entityId});
			this.onEntitiesUpdates.emit(this.entities);
		})
	}
}